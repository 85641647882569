import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Switch,
  Linking,
  Platform,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  article,
  articleItem,
  userProfile,
  button,
  FontFamily,
  FontWeight,
  Colors,
} from "../../styles/appStyles";
import CustomImage from "../custom-image";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import getImagePath from "../../utils/image-helper";
import ButtonGradient from "../button-gradient";
import Button from "../button";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeUserToken } from "../../../redux/User/user.actions";
import ErrorModal from "../error-modal";
import ContentLoader from "../content-loader";
import TouchableOpacityLink from "../touchable-opacity-link";
import { Picker } from "@react-native-picker/picker";
import { saveDarkMode, darkModeToggle } from "../../../redux/App/app.actions";
import CustomSwitch from "../custom-switch";
import AuthenticationWrapper from "../authentication-wrapper";

import Svg, {
  Circle,
  Ellipse,
  G,
  Text as SvgText,
  TSpan,
  TextPath,
  Path,
  Polygon,
  Polyline,
  Line,
  Rect,
  Use,
  Symbol,
  Defs,
  LinearGradient,
  RadialGradient,
  Stop,
  ClipPath,
  Pattern,
  Mask,
} from "react-native-svg";

const cookies = new Cookies();

const editProfile = getImagePath("icons/editProfile.png");
const cirles = getImagePath("img/colored-circles.png");
const logoutIcon = getImagePath("icons/logoutIcon.png");
const goldCoin = getImagePath("icons/goldCoin.png");
const silverCoin = getImagePath("icons/silverCoin.png");
const goldBg = getImagePath("icons/gold.png");
const silverBg = getImagePath("icons/silver.png");
const bookmarkMultiple = getImagePath("icons/bookmark-multiple-light.png");
const bookmarkMultipleWhite = getImagePath("icons/bookmark-multiple-white.png");
const unlockIcon = getImagePath("icons/unlockIcon.png");
const unlockIconWhite = getImagePath("icons/lock-icon-white.png");
const cirlesDark = getImagePath("img/color-circles-dark.png");
const goldBgDark = getImagePath("icons/goldBgDark.png");
const silverBgDark = getImagePath("icons/silverBgDark.png");
const moon = getImagePath("icons/moon.png");
const moonDark = getImagePath("icons/moon-dark.png");
import Auth0 from "react-native-auth0";

import { ChargebeeController } from '../../utils/chargebee'

const VALID_SUB_PLANS = ['2-year-plan-inr', 'student-two-year-plan-inr', 'annual-plan-in', 'gift-annual-plan-in', 'student-plan-inr', 'institution-plan-inr', 'team-plan-2-inr']

export class UserProfile extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    this.state = {
      billingPlatform: false,
      chargebeeSite: false,
      chargebeeSiteKey: false,
      redirectionLoader: false,
      token: token == undefined || token == "" ? false : token,
      pageLoader: true,
      showErrorModal: false,
      profileInfo: false,
      darkMode: false,
      notificationSubscription: {
        subscribed: true,
      },
      showPlan: false,
      showPrivilege: false,
      showStarterCedits: false,
      custom_user_id: false,
      subsLeft: 0
    };
  }

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }


  componentDidMount = () => {
    this.getUserProfile();
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
    this.getSubTransactions()
  };

  setChargebeeSite = () => {
    let { billingPlatform } = this.state;

    if (!billingPlatform) {
      console.log("No billing platform code to get CB site");
      return;
    }
    let chargebeeSiteIN = helpers.isWeb()
      ? process.env.CHARGEBEE_SITE_IN
      : config.CHARGEBEE_SITE_IN;
    let chargebeeSiteKeyIN = helpers.isWeb()
      ? process.env.CHARGEBEE_API_KEY_IN
      : config.CHARGEBEE_API_KEY_IN;
    let chargebeeSiteOT = helpers.isWeb()
      ? process.env.CHARGEBEE_SITE_OT
      : config.CHARGEBEE_SITE_OT;
    let chargebeeSiteKeyOT = helpers.isWeb()
      ? process.env.CHARGEBEE_API_KEY_OT
      : config.CHARGEBEE_API_KEY_OT;

    const chargebeeSite =
      billingPlatform == "chargebee_india" ? chargebeeSiteIN : chargebeeSiteOT;
    const ChargebeeSiteKey =
      chargebeeSite == chargebeeSiteIN
        ? chargebeeSiteKeyIN
        : chargebeeSiteKeyOT;

    this.setState({
      chargebeeSite: chargebeeSite,
      ChargebeeSiteKey: ChargebeeSiteKey,
    });
  };

  CB__HostedPage__CollectNow = () =>
    /*
        Opens CB hosted page URL(redirection) to collect view/pay unpaid invoices
    */ {
    const { token } = this.state;
    if (!token) {
      return;
    }

    this.setState({ redirectionLoader: true });
    ChargebeeController.activatedController().then(cb_handle => {
      cb_handle.hosted_page.collect_now()
    })
  }

  unpaidInvoiceMessage = () => {
    const { profileInfo } = this.state;

    let showNudge = false;
    if (profileInfo.subscription || profileInfo.team_subscription) {
      showNudge =
        profileInfo.subscription.can_renew ||
        profileInfo.team_subscription.can_renew;
    }

    const nudgeText =
      "You have one or multiple unpaid invoices, please pay to stop cancellation of your subscription.";
    const staleText = "Pay/view your pending invoices";

    return showNudge ? nudgeText : staleText;
  };

  getSubTransactions = () => {
    const { token } = this.state;
    let endpoint = '/get-sub-transactions';
    apiHelpers.apiRequest(endpoint, {}, "POST", true, token).then((res)=>{
        console.log(res)
        if(res.success){
            console.log("Transaction data --->", res.data)
            this.setState({subsLeft: 2-res.data.length})
        }
    }).catch((err)=>{
        console.log(err)
    })
}

  getUserProfile = () => {
    const { token } = this.state;
    let endpoint = `/profile`;
    const { isAdmin, referenceId } = this.props;
    let body = {};
    if (isAdmin) {
      body = {
        is_admin: true,
        reference_id: referenceId,
      };
    }
    // const {isAuthenticated, fetchToken} = this.props.auth
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then(async (res) => {
        if (res.success && res.code == "profile_info") {
          let { notificationSubscription } = this.state;
          notificationSubscription.subscribed =
            res.data.allow_app_notifications;
          if (Platform.OS == "ios") {
            let permissions_allowed =
              await helpers.checkNotificationPermissions();
            if (!permissions_allowed) {
              notificationSubscription.subscribed = false;
            }
          }
          console.log("PROFILE INFO ---->>>>", res.data)
          this.setState({
            profileInfo: res.data,
            pageLoader: false,
            notificationSubscription,
          });
        } else {
          if (isAdmin) {
            if (this.props.setNotAuthorized) {
              this.props.setNotAuthorized();
            }
          } else {
            this.setState({
              showErrorModal: true,
              pageLoader: false,
            });
          }
          this.authTokenExpired();
          console.log("Error=>>", endpoint, JSON.stringify(res));
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", error);
      });
  };

  setDarkMode = (darkMode, saveProfile) => {
    this.setState({
      darkMode: darkMode,
    });
    this.props.saveDarkMode(darkMode);
    this.props.darkModeToggle(false);
    let cookieDate = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
    cookies.set("dark_mode", darkMode, { path: "/", expires: cookieDate });
    const { token } = this.state;
    if (saveProfile && token) {
      let endpoint = `/edit-profile`;
      let body = helpers.isWeb()
        ? {
          web_dark_mode: darkMode,
        }
        : {
          app_dark_mode: darkMode,
        };
      apiHelpers
        .apiRequest(endpoint, body, "POST", true, token)
        .then((res) => {
          if (res.success) {
            //
          } else {
            console.log("Error=>>", JSON.stringify(res));
          }
        })
        .catch((error) => {
          console.log("Error=>> down", JSON.stringify(error));
        });
    }
  };

  checkNotificationPermission = async (item) => {
    if (Platform.OS == "android" || item.subscribed) {
      this.updateNotificationPref(item);
    } else {
      let permissions_allowed = await helpers.checkNotificationPermissions();
      if (permissions_allowed) {
        this.updateNotificationPref(item);
      } else {
        Linking.openSettings();
      }
    }
  };

  updateNotificationPref = (item) => {
    let { token } = this.state;
    let endpoint = `/edit-profile`;
    let body = {
      allow_app_notifications: !item.subscribed,
    };
    let { notificationSubscription } = this.state;
    notificationSubscription.subscribed = !item.subscribed;
    this.setState(
      { notificationSubscription: notificationSubscription },
      () => {
        apiHelpers
          .apiRequest(endpoint, body, "POST", true, token)
          .then((res) => {
            if (res.success) {
              console.log("edit profile success ==>", res);
            } else {
              let { notificationSubscription } = this.state;
              notificationSubscription.subscribed =
                !notificationSubscription.subscribed;
              this.setState({
                notificationSubscription: notificationSubscription,
              });
            }
          })
          .catch((error) => {
            console.log("edit profile error=>", error);
            if (error.status == 401) {
            } else {
              let { notificationSubscription } = this.state;
              notificationSubscription.subscribed =
                !notificationSubscription.subscribed;
              this.setState({
                notificationSubscription: notificationSubscription,
              });
            }
          });
      }
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <>
        <ContentLoader darkMode={darkMode} />
      </>
    );
  };

  navigateTo = (link) => {
    if (helpers.isWeb()) {
      this.props.history.push(link);
    } else {
      this.props.navigateScreen(link);
    }
  };

  pushLogoutEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "logout_click",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      element_title: 'logout',
      header_title: 'your account'
    });
  };

  checkCreditType = (profileInfo) => {
    const { credits } = profileInfo;
    const { non_privileged_credits, privileged_credits } = credits;
  
    if (non_privileged_credits?.available > 0) {
      return 'starter credits';
    } else if (privileged_credits?.available > 0) {
      return 'privilege credits';
    } else {
      return 'NA';
    }
  };
  

  pushProfileEventToDataLayer = (unlockedStoriesCount, element_title, credit_type, bookmark_count) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "profile_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.profileInfo ? "logged in" : "guest",
      page_location: window.location.href,
      bookmark_count: bookmark_count < 1 ? "NA" : bookmark_count,
      stories_unlocked: unlockedStoriesCount < 1 ? "NA" : unlockedStoriesCount,
      element_title: element_title.toLowerCase(),
      header_title: "your account",
      scroll_depth: helpers.getScrollDepth(),
      credit_type: credit_type,
    });
  };

  logout = () => {
    if (localStorage.getItem("isUserSubscriptionActive")) {
      localStorage.setItem("isUserSubscriptionActive", 0);
      localStorage.setItem("userSubscription", 0);
    }
    this.pushLogoutEventToDataLayer()
    this.setState({ logoutDisable: true });
    this.setDarkMode("off");
    if (helpers.isWeb()) {
      this.props.auth.logout({ returnTo: window.location.origin });
    } else {
      if (Platform.OS == "ios") {
        this.props.removeUserToken();
        this.props.navigateScreen("/");
        return true;
      }
      const auth0 = new Auth0({
        domain: process.env.AUTH0_DOMAIN,
        clientId: process.env.AUTH0_CLIENT_ID,
      });
      auth0.webAuth
        .clearSession({})
        .then((success) => {
          this.props.removeUserToken();
          this.props.navigateScreen("/");
        })
        .catch((error) => {
          console.log("Log out cancelled");
          this.props.navigateScreen("/");
        });
    }

    helpers.setSubscriptionData(
      undefined,
      false,
      null,
      null,
      false,
      null,
      false,
      false
    );
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      this.props.navigateToScreen("/sign-in");
    } else {
      let existingToken = this.props.user?.userData?.data?.token;
      if (!existingToken) {
        this.props.navigateScreen("/sign-in");
        return true;
      }
      this.props.removeUserToken();
      this.props.navigateScreen("/sign-in");
    }
  };

  pushSubscribeEventToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "subscribe_initiate",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in" : "guest",
      header_title: "your account",
      banner_name: "NA",
      CTA_text: "subscribe now",
      CTA_position: "bottom",
      scroll_depth: helpers.getScrollDepth(),
      category_type: "NA",
    });
  }

  renderPageContent() {
    const {
      profileInfo,
      darkMode,
      notificationSubscription,
      redirectionLoader,
    } = this.state;
    const { isAdmin, referenceId } = this.props;
    let userPlan =
      profileInfo.subscription &&
        JSON.stringify(profileInfo.subscription) != "{}"
        ? profileInfo.subscription.plan.name
        : profileInfo.team_subscription &&
          JSON.stringify(profileInfo.team_subscription) != "{}" &&
          profileInfo.team_subscription.is_team_member
          ? profileInfo.team_subscription.plan.name
          : false;
    let webView = helpers.isWeb();
    let btnClickReadingList, btnClickUnlockedArticles;
    let annualSub =
      profileInfo?.subscription?.plan?.description?.full_access ||
      (profileInfo?.team_subscription?.plan?.description?.full_access &&
        profileInfo?.team_subscription?.is_team_member);
    if (webView) {
      btnClickReadingList = {
        to: "/bookmarks",
      };
      btnClickUnlockedArticles = {
        to: annualSub ? "/all-stories" : "/unlocked-stories",
      };
    } else {
      btnClickReadingList = {
        onPress: () => this.navigateTo("/bookmarks"),
      };
      btnClickUnlockedArticles = {
        onPress: () =>
          this.navigateTo(annualSub ? "/all-stories" : "/unlocked-stories"),
      };
    }
    let unlockedStoriesCount = annualSub
      ? "All"
      : profileInfo.user_story_info?.unlock_count
        ? profileInfo.user_story_info.unlock_count
        : 0;
    let darkModeOption = helpers.isDarkMode(this.props?.app?.DarkMode);
    let darkModeValue = helpers.isWeb()
      ? cookies.get("dark_mode")
      : this.props?.app?.DarkMode;
    let bookmark_count = profileInfo.user_story_info?.bookmark_count
    ? profileInfo.user_story_info.bookmark_count
    : 0
    return (
      <View>
        <View
          style={{
            backgroundColor: darkModeOption ? "#012137" : "#FFEEEA",
            paddingVertical: 28,
            paddingHorizontal: 20,
          }}
        >
          <Text
            style={[
              userProfile.userGreeting,
              { color: Colors.black },
              darkModeOption && { color: Colors.lightWhite },
            ]}
          >
            Good {helpers.getGreetingTime()}
          </Text>
          <Text
            style={[
              userProfile.userName,
              { color: Colors.black },
              darkModeOption && { color: Colors.lightWhite },
            ]}
          >
            {profileInfo.name}
          </Text>
          <Text
            style={[
              userProfile.userEmail,
              { color: Colors.black },
              darkModeOption && { color: Colors.lightWhite },
            ]}
          >
            {profileInfo.email}
          </Text>
          <Button
            title={isAdmin ? "View Profile" : "Edit Profile"}
            rootStyle={{
              btnWrapper: [
                button.whiteButton,
                {
                  borderColor: "#FF6B75",
                  width: helpers.isWeb() ? "fit-content" : "auto",
                  backgroundColor: darkModeOption
                    ? "#FF6B75"
                    : "rgba(255, 255, 255, 0.7)",
                },
                !helpers.isWeb() && { alignSelf: "flex-start" },
              ],
              btnText: [
                button.whiteButtonText,
                {
                  color: darkModeOption ? Colors.lightWhite : "#FF6B75",
                  lineHeight: 14,
                },
              ],
            }}
            onClick={() => 
              {
                this.pushProfileEventToDataLayer(unlockedStoriesCount, isAdmin ? "View Profile" : "Edit Profile", this.checkCreditType(profileInfo), bookmark_count)
                this.props.navigateScreen("accounts/edit-profile")
              }
            }
          />
          <View
            style={[
              Flex.row,
              Flex.alignCenter,
              Flex.justifyBetween,
              {
                position: "absolute",
                top: 43,
                right: 28,
                width: helpers.isWeb() ? 102 : 102,
              },
            ]}
          >
            <View style={{ position: "relative" }}>
              <View style={[Flex.row, Flex.alignCenter]}>
                <CustomImage
                  require={true}
                  source={goldCoin}
                  style={{ width: 18, height: 18 }}
                  webStyle={{ width: 18, height: 18 }}
                />
                <Text
                  style={{
                    fontFamily: FontFamily.regular,
                    fontWeight: "500",
                    fontSize: 14,
                    zIndex: helpers.isWeb() ? -1 : 0,
                    marginLeft: 4,
                  }}
                >
                  {profileInfo?.credits?.privileged_credits
                    ? profileInfo.credits.privileged_credits.available
                    : 0}
                </Text>
              </View>
              <CustomImage
                require={true}
                source={darkModeOption ? goldBgDark : goldBg}
                style={{
                  width: 60,
                  height: 30,
                  zIndex: -1,
                  position: "absolute",
                  top: -6,
                  right: -10,
                }}
                webStyle={{
                  width: 60,
                  height: 30,
                  zIndex: -1,
                  position: "absolute",
                  top: -6,
                  right: -12,
                }}
              />
            </View>
            <View>
              <View style={[Flex.row, Flex.alignCenter]}>
                <CustomImage
                  require={true}
                  source={silverCoin}
                  style={{ width: 18, height: 18 }}
                  webStyle={{ width: 18, height: 18 }}
                />
                <Text
                  style={{
                    fontFamily: FontFamily.regular,
                    fontWeight: "500",
                    fontSize: 14,
                    zIndex: helpers.isWeb() ? -1 : 0,
                    marginLeft: 5,
                  }}
                >
                  {profileInfo?.credits?.non_privileged_credits
                    ? profileInfo.credits.non_privileged_credits.available
                    : 0}
                </Text>
              </View>
              <CustomImage
                require={true}
                source={darkModeOption ? silverBgDark : silverBg}
                style={{
                  width: 60,
                  height: 30,
                  zIndex: -1,
                  position: "absolute",
                  top: -6,
                  right: -14,
                }}
                webStyle={{
                  width: 60,
                  height: 30,
                  zIndex: -1,
                  position: "absolute",
                  top: -6,
                  right: -12,
                }}
              />
            </View>
          </View>
          <CustomImage
            require={true}
            source={darkModeOption ? cirlesDark : cirles}
            style={{
              width: 136,
              height: 71,
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
            webStyle={{
              width: 136,
              height: 71,
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
          />
        </View>
        {!isAdmin && (
          <>
            <View style={[Flex.row, Flex.fill]}>
              <TouchableOpacityLink
                {...btnClickReadingList}
                handleClick={()=> this.pushProfileEventToDataLayer(unlockedStoriesCount, "ReadingList", this.checkCreditType(profileInfo), bookmark_count)}
                style={{ flex: 1 }}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignStart,
                    Padding.pl_2,
                    {
                      flex: 1,
                      alignItems: "center",
                      borderRightWidth: 1,
                      borderColor: darkModeOption
                        ? Colors.borderColorLight
                        : "rgba(0,0,0,0.3)",
                      paddingBottom: 15,
                      paddingTop: 15,
                    },
                    darkMode && { paddingBottom: 15, paddingTop: 15 },
                  ]}
                >
                  <CustomImage
                    require={true}
                    source={
                      darkModeOption ? bookmarkMultipleWhite : bookmarkMultiple
                    }
                    style={{
                      width: 20,
                      height: 29,
                      marginRight: 15,
                      marginTop: 2,
                    }}
                    webStyle={{
                      width: 20,
                      height: 29,
                      paddingRight: 15,
                      marginTop: 2,
                    }}
                  />
                  <View>
                    <View style={[Flex.row, Flex.alignCenter]}>
                      <Text
                        style={[
                          userProfile.count,
                          { color: Colors.black },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {profileInfo.user_story_info?.bookmark_count
                          ? profileInfo.user_story_info.bookmark_count
                          : 0}
                      </Text>
                      <Text
                        style={[
                          userProfile.counterText,
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {" "}
                        {profileInfo.user_story_info?.bookmark_count
                          ? profileInfo.user_story_info.bookmark_count
                            ? profileInfo.user_story_info.bookmark_count === 1
                              ? "story"
                              : "stories"
                            : "stories"
                          : "stories"}
                      </Text>
                    </View>
                    <Text
                      style={[
                        userProfile.counterTextLower,
                        darkModeOption && { color: Colors.lineColor },
                      ]}
                    >
                      bookmarked
                    </Text>
                  </View>
                </View>
              </TouchableOpacityLink>
              <TouchableOpacityLink
                {...btnClickUnlockedArticles}
                handleClick={()=> this.pushProfileEventToDataLayer(unlockedStoriesCount, "UnlockedArticles", this.checkCreditType(profileInfo), bookmark_count)}
                style={{ flex: 1 }}
              >
                <View
                  style={[
                    Flex.row,
                    Flex.alignStart,
                    Padding.pl_2,
                    {
                      flex: 1,
                      alignItems: "center",
                      borderRightWidth: 1,
                      borderColor: darkModeOption
                        ? Colors.borderColorLight
                        : "rgba(0,0,0,0.3)",
                      paddingBottom: 15,
                      paddingTop: 15,
                    },
                    darkMode && { paddingBottom: 15, paddingTop: 15 },
                  ]}
                >
                  <CustomImage
                    require={true}
                    source={darkModeOption ? unlockIconWhite : unlockIcon}
                    style={{
                      width: 22,
                      height: 29,
                      marginRight: 15,
                      resizeMode: "contain",
                      marginTop: 2,
                    }}
                    webStyle={{
                      width: 20,
                      height: 29,
                      paddingRight: 15,
                      objectFit: "contain",
                      marginTop: 2,
                    }}
                  />
                  <View>
                    <View style={[Flex.row, Flex.alignCenter]}>
                      <Text
                        style={[
                          userProfile.count,
                          { color: "#000" },
                          annualSub && !helpers.isWeb() && { fontSize: 20 },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {unlockedStoriesCount}
                      </Text>
                      <Text
                        style={[
                          userProfile.counterText,
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {" "}
                        {unlockedStoriesCount === 1 ? "story" : "stories"}
                      </Text>
                    </View>
                    <Text
                      style={[
                        userProfile.counterTextLower,
                        darkModeOption && { color: Colors.lineColor },
                      ]}
                    >
                      unlocked
                    </Text>
                  </View>
                </View>
              </TouchableOpacityLink>
            </View>
            {/* {webView && (
              <>
                <View
                  style={[
                    { borderTopWidth: 0.3, borderColor: "rgba(0,0,0,0.3)" },
                    darkModeOption && { borderColor: Colors.borderColorLight },
                  ]}
                ></View>
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyBetween,
                    Padding.ph_2,
                  ]}
                >
                  <View
                    style={[
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      {
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 0,
                        borderRadius: 2,
                        flex: 1,
                      },
                    ]}
                  >
                    <CustomImage
                      source={darkModeOption ? moon : moonDark}
                      require={true}
                      style={{ width: 25, height: 25 }}
                      webStyle={{ width: 25, height: 25 }}
                    />
                    <View
                      style={[
                        Flex.row,
                        Flex.alignCenter,
                        Flex.justifyBetween,
                        { flex: 1 },
                      ]}
                    >
                      <Text
                        style={[
                          userProfile.planName,
                          Padding.pv_1,
                          Padding.ph_1,
                          !helpers.isWeb() && {
                            fontFamily: FontFamily.semiBold,
                          },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {darkModeValue == "on"
                          ? "DARK MODE"
                          : darkModeValue == "off"
                            ? "LIGHT MODE"
                            : "SYSTEM DEFAULT"}
                      </Text>
                      <View>
                        <Switch
                          trackColor={{
                            false: Colors.switchTrackInActive,
                            true: Colors.switchTrackActive,
                          }}
                          thumbColor={Colors.switchThumbActive}
                          onValueChange={() =>
                            this.setDarkMode(
                              darkModeValue == "on" ? "off" : "on",
                              true
                            )
                          }
                          value={darkModeValue == "on" ? true : false}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </>
            )} */}
            {webView && (
              <>
                <View
                  style={[
                    { borderTopWidth: 0.3, borderColor: "rgba(0,0,0,0.3)" },
                    darkModeOption && { borderColor: Colors.borderColorLight },
                  ]}
                ></View>
                <View
                  style={[
                    Flex.row,
                    Flex.alignCenter,
                    Flex.justifyBetween,
                    Padding.ph_2,
                  ]}
                >
                  <View
                    style={[
                      Flex.row,
                      Flex.alignCenter,
                      Flex.justifyBetween,
                      {
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 0,
                        borderRadius: 2,
                        flex: 1,
                      },
                    ]}
                  >
                    {/* <CustomImage
                                        source={warningIcon}
                                        require={true}
                                        style={{ width: 25, height: 25 }}
                                        webStyle={{ width: 25, height: 25 }}
                                    /> */}
                    {/* <View
                      style={[
                        Flex.row,
                        Flex.alignCenter,
                        Flex.justifyBetween,
                        { flex: 1 },
                      ]}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          style={[
                            userProfile.planName,
                            !helpers.isWeb() && {
                              fontFamily: FontFamily.semiBold,
                            },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          Unpaid Invoices
                        </Text>
                        <Text
                          style={[
                            userProfile.heading,
                            { fontWeight: "400", color: "#757575" },
                            darkModeOption && { color: Colors.lineColor },
                          ]}
                        >
                          {this.unpaidInvoiceMessage()}
                        </Text>
                      </View>
                      <TouchableOpacity>
                        <View>
                          {redirectionLoader ? (
                            <ActivityIndicator
                              size="small"
                              style={{
                                flex: 1,
                                justifyContent: "right",
                                alignItems: "right",
                                height: 50,
                              }}
                            />
                          ) : (
                            <Button
                              onClick={() => this.CB__HostedPage__CollectNow()}
                              title={"Pay/View"}
                              rootStyle={{
                                btnWrapper: [
                                  button.whiteButton,
                                  {
                                    borderColor: "#FF6B75",
                                    width: helpers.isWeb()
                                      ? "fit-content"
                                      : "auto",
                                    backgroundColor: darkModeOption
                                      ? "#FF6B75"
                                      : "rgba(255, 255, 255, 0.7)",
                                  },
                                  !helpers.isWeb() && {
                                    alignSelf: "flex-start",
                                  },
                                  { top: "50%" },
                                  { marginTop: 0 },
                                ],
                                btnText: [
                                  button.whiteButtonText,
                                  {
                                    color: darkModeOption
                                      ? Colors.lightWhite
                                      : "#FF6B75",
                                    lineHeight: 14,
                                  },
                                ],
                              }}
                            ></Button>
                          )}
                        </View>
                      </TouchableOpacity>
                    </View> */}
                  </View>
                </View>
              </>
            )}
          </>
        )}
        <View style={[Padding.pt_0]}>
          <View
            style={[Padding.pt_0, Padding.pb_3, Padding.pl_2, Padding.pr_2]}
          >
            <View
              style={[
                {
                  borderTopWidth: 0.3,
                  borderColor: "rgba(0,0,0,0.3)",
                  marginRight: -30,
                  marginLeft: -30,
                  paddingBottom: 20,
                },
                darkModeOption && { borderColor: Colors.borderColorLight },
              ]}
            ></View>
            {/* {!isAdmin && !webView && (
              <>
                <View
                  style={[
                    { borderBottomWidth: 0.3, borderColor: "rgba(0,0,0,0.3)" },
                    darkModeOption && { borderColor: Colors.borderColorLight },
                  ]}
                >
                  <Text
                    style={[
                      userProfile.heading,
                      { textTransform: "uppercase" },
                      darkModeOption && { color: Colors.greyTextDark },
                    ]}
                  >
                    View Options
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() =>
                    this.props.navigateScreen("accounts/dark-mode")
                  }
                >
                  <View
                    style={[
                      Padding.pt_2,
                      Padding.pb_2,
                      {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={[
                          userProfile.planName,
                          !helpers.isWeb() && {
                            fontFamily: FontFamily.semiBold,
                          },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {darkModeValue == "on"
                          ? "DARK MODE"
                          : darkModeValue == "off"
                            ? "LIGHT MODE"
                            : "SYSTEM DEFAULT"}
                      </Text>
                      <Text
                        style={[
                          userProfile.heading,
                          { fontWeight: "400", color: "#757575" },
                          darkModeOption && { color: Colors.lineColor },
                          Margin.mb_4,
                        ]}
                      >
                        Configure your display
                      </Text>
                    </View>
                    <View
                      style={[Flex.row, Flex.alignCenter, Flex.justifyBetween]}
                    >
                      <CustomImage
                        require={true}
                        source={editProfile}
                        style={{ width: 10, height: 18 }}
                        webStyle={{ width: 10, height: 18 }}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              </>
            )} */}

            <View>
              <Text
                style={[
                  userProfile.heading,
                  { textTransform: "uppercase" },
                  darkModeOption && { color: Colors.greyTextDark },
                ]}
              >
                YOUR ACCOUNT
              </Text>
            </View>
            {/* for showing alert before 10days of plan */}
            {this.state.showPlan ? (
              <View style={[Margin.mt_1, Margin.mb_1, userProfile.alertBox]}>
                <Text
                  style={[
                    Margin.mb_1,
                    {
                      color: Colors.whiteColor,
                      textAlign: "center",
                      fontSize: 12,
                    },
                  ]}
                >
                  Your subscription with The Morning Context will end on{" "}
                  {
                    (profileInfo?.subscription?.end_date)
                      .toISOString()
                      .split("T")[0]
                  }
                  .
                </Text>
                <Text
                  style={{
                    color: Colors.whiteColor,
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  RENEW PLAN
                </Text>
              </View>
            ) : null}
            {/* for showing after plan expries */}
            {!profileInfo?.subscription ? (
              <View style={[Margin.mt_1, Margin.mb_1, userProfile.alertBox]}>
                <Text
                  style={[
                    Margin.mb_1,
                    {
                      color: Colors.whiteColor,
                      textAlign: "center",
                      fontSize: 12,
                    },
                  ]}
                >
                  Your subscription with The Morning Context has expired.
                </Text>
                <Text
                  style={{
                    color: Colors.whiteColor,
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  RENEW PLAN
                </Text>
              </View>
            ) : null}
            {/* show previledge expiration */}
            {/*  {((profileInfo?.credits?.privileged_credits?.available >= 1) && (profileInfo?.credits?.privileged_credits?.available <= 5)) ? (
              <View style={[Margin.mt_1, Margin.mb_1, userProfile.alertBox]}>
                <Text
                  style={[
                    Margin.mb_1,
                    {
                      color: Colors.whiteColor,
                      textAlign: "center",
                      fontSize: 12,
                    },
                  ]}
                >
                  Your privilege credits are about to expire.
                </Text>
                <TouchableOpacity
                  onPress={() =>
                    this.props.history.push("/pricing")
                  }
                >
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    UPGRADE PLAN
                  </Text>
                </TouchableOpacity>
              </View>
            ) : null}*/}
            {/* for priviledge expired */}
            {profileInfo &&
              profileInfo.credits &&
              profileInfo.credits.privileged_credits &&
              profileInfo.credits.privileged_credits.total_credits &&
              profileInfo.credits.privileged_credits.total_credits == 10 &&
              profileInfo.credits.privileged_credits.expired == 10 ? (
              <View style={[Margin.mt_1, Margin.mb_1, userProfile.alertBox]}>
                <Text
                  style={[
                    Margin.mb_1,
                    {
                      color: Colors.whiteColor,
                      textAlign: "center",
                      fontSize: 12,
                    },
                  ]}
                >
                  Your privilege credits have expired.
                </Text>
                <Text
                  style={{
                    color: Colors.whiteColor,
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  UPGRADE PLAN
                </Text>
              </View>
            ) : null}

            {/* show starter credit expiration */}
            {this.state.showStarterCedits ? (
              <View style={[Margin.mt_1, Margin.mb_1, userProfile.alertBox]}>
                <Text
                  style={[
                    Margin.mb_1,
                    {
                      color: Colors.whiteColor,
                      textAlign: "center",
                      fontSize: 12,
                    },
                  ]}
                >
                  Your starter credits are about to expire.
                </Text>
                <Text
                  style={{
                    color: Colors.whiteColor,
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  UPGRADE PLAN
                </Text>
              </View>
            ) : null}
            {/* for priviledge expired */}
            {profileInfo &&
              profileInfo.credits &&
              profileInfo.non_privileged_credits &&
              pprofileInfo.credits.non_privileged_credits.total_credits &&
              profileInfo.credits.non_privileged_credits.total_credits == 10 &&
              profileInfo.credits.non_privileged_credits.expired == 10 ? (
              <View style={[Margin.mt_1, Margin.mb_1, userProfile.alertBox]}>
                <Text
                  style={[
                    Margin.mb_1,
                    {
                      color: Colors.whiteColor,
                      textAlign: "center",
                      fontSize: 12,
                    },
                  ]}
                >
                  Your starter credits have expired.
                </Text>
                <Text
                  style={{
                    color: Colors.whiteColor,
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  UPGRADE PLAN
                </Text>
              </View>
            ) : null}
            {/* <Text style={[userProfile.heading, { textTransform: 'uppercase', borderBottomWidth: 0.3, borderColor: 'rgba(0,0,0,0.3)' }, darkModeOption && { color: Colors.greyTextDark, borderColor: Colors.borderColorLight }]}></Text> */}
            <View
              style={[
                helpers.isDesktop() && Flex.row,
                Flex.FlexWrap,
                Flex.alignStretch,
                Margin.margin__15,
                !helpers.isDesktop() && Flex.column,
                Flex.FlexWrap,
                Flex.alignStretch,
                Margin.margin__15,
              ]}
            >
              {!isAdmin && webView && (
                <View
                  style={[
                    helpers.isDesktop() && { maxWidth: "50%", flex: "0 0 50%" },
                    { width: "100%", padding: 10 },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() =>
                      {
                        this.pushProfileEventToDataLayer(unlockedStoriesCount, "Manage Subscription", this.checkCreditType(profileInfo), bookmark_count)
                        this.props.showCBPortal(null, null, null, null)
                      }
                    }
                  >
                    <View
                      style={[
                        userProfile.linkCard,
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        },
                      ]}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          style={[
                            userProfile.planName,
                            !helpers.isWeb() && {
                              fontFamily: FontFamily.semiBold,
                            },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          Manage Subscription
                        </Text>
                        <Text
                          style={[
                            userProfile.heading,
                            { fontWeight: "400", color: "#757575" },
                            darkModeOption && { color: Colors.lineColor },
                          ]}
                        >
                          Payment Method/Billing History
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              )}

              <View
                style={[
                  helpers.isDesktop() && { maxWidth: "50%", flex: "0 0 50%" },
                  { width: "100%", padding: 10 },
                ]}
              >
                <TouchableOpacity
                  onPress={() =>
                    {
                      this.pushProfileEventToDataLayer(unlockedStoriesCount, "View or upgrade your current plan", this.checkCreditType(profileInfo), bookmark_count)
                      this.props.navigateScreen("accounts/subscriptions")
                    }
                  }
                >
                  <View
                    style={[
                      userProfile.linkCard,
                      {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={[
                          userProfile.planName,
                          !helpers.isWeb() && {
                            fontFamily: FontFamily.semiBold,
                          },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {userPlan ? userPlan : "No Plan"}
                        {Math.floor(
                          Date.parse(profileInfo?.subscription?.end_date) -
                          Date.parse(new Date().toISOString()) / 86400000
                        ) < 10 ? (
                          <>
                            <Svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <Circle
                                opacity="0.2"
                                cx="7"
                                cy="7"
                                r="7"
                                fill="#E5707E"
                              />
                              <circle cx="7" cy="7" r="4" fill="#E5707E" />
                            </Svg>
                            {this.setState({ showPlan: true })}
                          </>
                        ) : null}
                      </Text>
                      <Text
                        style={[
                          userProfile.heading,
                          { fontWeight: "400", color: "#757575" },
                          darkModeOption && { color: Colors.lineColor },
                        ]}
                      >
                        View or upgrade your current plan
                        {/* {userPlan
                          ? "View your current plan"
                          : "Select a new plan"} */}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <View
                style={[
                  helpers.isDesktop() && { maxWidth: "50%", flex: "0 0 50%" },
                  { width: "100%", padding: 10 },
                ]}
              >
                <TouchableOpacity
                  onPress={() =>
                    {
                      this.pushProfileEventToDataLayer(unlockedStoriesCount, "Privilege Credits", this.checkCreditType(profileInfo), bookmark_count)
                      this.props.navigateScreen("accounts/privileged-credits", {
                        credits: profileInfo?.credits?.privileged_credits
                          ? profileInfo.credits.privileged_credits.available
                          : 0,
                      })
                    }
                  }
                  style={{}}
                >
                  <View
                    style={[
                      userProfile.linkCard,
                      {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={[
                          userProfile.planName,
                          !helpers.isWeb() && {
                            fontFamily: FontFamily.semiBold,
                          },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        Privilege Credits
                        {/* {((this.profileInfo?.credits?.privileged_credits?.available >= 1) && (this.profileInfo?.credits?.privileged_credits?.available <= 20)) ? (
                          <>
                            <Svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <Circle
                                opacity="0.2"
                                cx="7"
                                cy="7"
                                r="7"
                                fill="#E5707E"
                              />
                              <Circle cx="7" cy="7" r="4" fill="#E5707E" />
                            </Svg>

                            {this.setState({ showPrivilege: true })}
                          </>
                        ) : null} */}
                      </Text>
                      <Text
                        style={[
                          userProfile.heading,
                          { fontWeight: "400", color: "#757575" },
                          darkModeOption && { color: Colors.lineColor },
                        ]}
                      >
                        {profileInfo.free_credits_available != undefined ||
                          profileInfo.free_credits_available != null
                          ? profileInfo.free_credits_available == 0
                            ? "Gift free privilege credits to your friends"
                            : `${profileInfo.free_credits_available}/3 credits can be gifted this month`
                          : `${profileInfo?.credits?.privileged_credits
                            ? profileInfo.credits.privileged_credits
                              .available
                            : 0
                          } Credits. Click here to add more`}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              {/* starter credits section */}
              <View
                style={[
                  helpers.isDesktop() && { maxWidth: "50%", flex: "0 0 50%" },
                  { width: "100%", padding: 10 },
                ]}
              >
                <TouchableOpacity
                  onPress={() =>
                    {
                      this.pushProfileEventToDataLayer(unlockedStoriesCount, "Starter Credits", this.checkCreditType(profileInfo), bookmark_count)
                      this.props.navigateScreen("accounts/starter-credits", {
                        credits: profileInfo?.credits?.non_privileged_credits
                          ? profileInfo.credits.non_privileged_credits.available
                          : 0,
                      })
                    }
                  }
                  style={{}}
                >
                  <View
                    style={[
                      userProfile.linkCard,
                      {
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={[
                          userProfile.planName,
                          !helpers.isWeb() && {
                            fontFamily: FontFamily.semiBold,
                          },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        Starter Credits
                        {/* {profileInfo.credits.non_privileged_credits.expired >
                          7 ? (
                          <>
                            <Svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <Circle
                                opacity="0.2"
                                cx="7"
                                cy="7"
                                r="7"
                                fill="#E5707E"
                              />
                              <Circle cx="7" cy="7" r="4" fill="#E5707E" />
                            </Svg>
                            {this.setState({ showStarterCedits: true })}
                          </>
                        ) : null} */}
                      </Text>
                      <Text
                        style={[
                          userProfile.heading,
                          { fontWeight: "400", color: "#757575" },
                          darkModeOption & { color: Colors.lineColor },
                        ]}
                      >
                        {profileInfo?.credits?.non_privileged_credits
                          ? profileInfo.credits.non_privileged_credits.available
                          : 0}{" "}
                        Credits available
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              {webView && (
                <View
                  style={[
                    helpers.isDesktop() && { maxWidth: "50%", flex: "0 0 50%" },
                    { width: "100%", padding: 10 },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() => 
                      {
                        this.pushProfileEventToDataLayer(unlockedStoriesCount, "Transaction History", this.checkCreditType(profileInfo), bookmark_count)
                        this.props.navigateScreen("accounts/orders")
                      }
                    }
                    style={{}}
                  >
                    <View
                      style={[
                        userProfile.linkCard,
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        },
                      ]}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          style={[
                            userProfile.planName,
                            !helpers.isWeb() && {
                              fontFamily: FontFamily.semiBold,
                            },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          Transaction History
                        </Text>
                        <Text
                          style={[
                            userProfile.heading,
                            { fontWeight: "400", color: "#757575" },
                            darkModeOption && { color: Colors.lineColor },
                          ]}
                        >
                          View all transactions
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              )}

              {webView && VALID_SUB_PLANS.includes(this.state.profileInfo?.subscription?.plan?.id) && (
                <View
                  style={[
                    helpers.isDesktop() && { maxWidth: "50%", flex: "0 0 50%" },
                    { width: "100%", padding: 10 },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() => 
                      {
                        this.props.navigateScreen("accounts/referral")
                      }
                    }
                    style={{}}
                  >
                    <View
                      style={[
                        userProfile.linkCard,
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        },
                      ]}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          style={[
                            userProfile.planName,
                            !helpers.isWeb() && {
                              fontFamily: FontFamily.semiBold,
                            },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          Gift Subscriptions
                        </Text>
                        <Text
                          style={[
                            userProfile.heading,
                            { fontWeight: "400", color: "#757575" },
                            darkModeOption && { color: Colors.lineColor },
                          ]}
                        >
                          {this.state.subsLeft}/2 gift subscriptions can be gifted
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                </View>
              )}

              <View
                style={[
                  helpers.isDesktop() && { maxWidth: "100%", flex: "0 0 100%" },
                  { width: "100%", padding: 10 },
                ]}
              >
                <View
                  style={{
                    padding: 20,
                    backgroundColor: "#F3F5FF",
                    borderRadius: 8,
                  }}
                >
                  <Text
                    style={[
                      userProfile.heading,
                      { textTransform: "uppercase" },
                      darkModeOption && { color: Colors.greyTextDark },
                    ]}
                  >
                    EXCLUSIVE SUBSCRIBER-ONLY BENEFITS{" "}
                  </Text>
                  <TouchableOpacity
                    style={[Margin.mt_1, Margin.mb_1]}
                    onPress={() =>
                      {
                        this.pushProfileEventToDataLayer(unlockedStoriesCount, "Join our whatsapp channel", this.checkCreditType(profileInfo), bookmark_count)
                        window.open(
                          "https://www.whatsapp.com/channel/0029VaBON51InlqTO0if5g1f",
                          "_blank"
                        )
                      }
                    }
                  >
                    <View
                      style={[
                        { display: "flex" },
                        Flex.alignCenter,
                        Flex.justifyBetween,
                        Flex.row,
                      ]}
                    >
                      <View
                        style={[
                          { display: "flex" },
                          Flex.alignCenter,
                          Flex.row,
                        ]}
                      >
                        <Svg
                          width="21"
                          height="18"
                          viewBox="0 0 21 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <Path
                            d="M18.915 0.71682L1.185 7.55382C-0.0249968 8.03982 -0.0179967 8.71482 0.963003 9.01582L5.515 10.4358L16.047 3.79082C16.545 3.48782 17 3.65082 16.626 3.98282L8.093 11.6838H8.091L8.093 11.6848L7.779 16.3768C8.239 16.3768 8.442 16.1658 8.7 15.9168L10.911 13.7668L15.51 17.1638C16.358 17.6308 16.967 17.3908 17.178 16.3788L20.197 2.15082C20.506 0.91182 19.724 0.35082 18.915 0.71682Z"
                            fill="#051462"
                          />
                        </Svg>
                        <Text style={[Margin.ml_1]}>
                          Join Our WhatsApp Channel
                        </Text>
                      </View>
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          Flex.justifyBetween,
                        ]}
                      >
                        <CustomImage
                          require={true}
                          source={editProfile}
                          style={{ width: 10, height: 18 }}
                          webStyle={{ width: 10, height: 18 }}
                        />
                      </View>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[Margin.mt_1, Margin.mb_1]}
                    onPress={() => 
                      {
                        this.pushProfileEventToDataLayer(unlockedStoriesCount, "Download The Morning Context App", this.checkCreditType(profileInfo), bookmark_count)
                        this.props.history.push("/app-download")
                      }   
                    }
                  >
                    <View
                      style={[
                        { display: "flex" },
                        Flex.alignCenter,
                        Flex.justifyBetween,
                        Flex.row,
                      ]}
                    >
                      <View
                        style={[
                          { display: "flex" },
                          Flex.alignCenter,
                          Flex.row,
                        ]}
                      >
                        <Svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <Path
                            d="M18.8571 0H6.85714C6.40248 0 5.96645 0.180612 5.64496 0.502103C5.32346 0.823593 5.14285 1.25963 5.14285 1.71429V22.2857C5.14285 22.7404 5.32346 23.1764 5.64496 23.4979C5.96645 23.8194 6.40248 24 6.85714 24H18.8571C19.3118 24 19.7478 23.8194 20.0693 23.4979C20.3908 23.1764 20.5714 22.7404 20.5714 22.2857V1.71429C20.5714 1.25963 20.3908 0.823593 20.0693 0.502103C19.7478 0.180612 19.3118 0 18.8571 0ZM13.7143 22.2857H12V20.5714H13.7143V22.2857ZM6.85714 18.8571V1.71429H18.8571V18.8571H6.85714Z"
                            fill="#051462"
                          />
                          <Path
                            d="M17.3333 17.3333H8V16H17.3333V17.3333ZM12.6667 14.6667L8.66667 10.6667L9.60667 9.72667L12 12.1133V4H13.3333V12.1133L15.7267 9.72667L16.6667 10.6667L12.6667 14.6667Z"
                            fill="#051462"
                          />
                        </Svg>
                        <Text style={[Margin.ml_1]}>
                          Download The Morning Context App
                        </Text>
                      </View>
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          Flex.justifyBetween,
                        ]}
                      >
                        <CustomImage
                          require={true}
                          source={editProfile}
                          style={{ width: 10, height: 18 }}
                          webStyle={{ width: 10, height: 18 }}
                        />
                      </View>
                    </View>
                  </TouchableOpacity>
                  {/* <TouchableOpacity
                    style={[Margin.mt_1, Margin.mb_1]}
                    onPress={() =>
                      {
                        this.pushProfileEventToDataLayer(unlockedStoriesCount, "Subscribe to a Newsletter", this.checkCreditType(profileInfo), bookmark_count)
                        this.props.history.push("#accounts/newsletters")
                      }
                    }
                  >
                    <View
                      style={[
                        { display: "flex" },
                        Flex.alignCenter,
                        Flex.justifyBetween,
                        Flex.row,
                      ]}
                    >
                      <View
                        style={[
                          { display: "flex" },
                          Flex.alignCenter,
                          Flex.row,
                        ]}
                      >
                        <Svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <Path
                            d="M4 8.00001L12 13L20 8.00001L12 3.00001L4 8.00001ZM22 8.00001V18C22 18.5304 21.7893 19.0392 21.4142 19.4142C21.0391 19.7893 20.5304 20 20 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0392 2 18.5304 2 18V8.00001C2 7.27001 2.39 6.64001 2.97 6.29001L12 0.640015L21.03 6.29001C21.61 6.64001 22 7.27001 22 8.00001Z"
                            fill="#051462"
                          />
                        </Svg>
                        <Text style={[Margin.ml_1]}>
                          Subscribe to a Newsletter
                        </Text>
                      </View>
                      <View
                        style={[
                          Flex.row,
                          Flex.alignCenter,
                          Flex.justifyBetween,
                        ]}
                      >
                        <CustomImage
                          require={true}
                          source={editProfile}
                          style={{ width: 10, height: 18 }}
                          webStyle={{ width: 10, height: 18 }}
                        />
                      </View>
                    </View>
                  </TouchableOpacity> */}
                </View>
              </View>
            </View>
          </View>
          <View
            style={[Padding.pt_1, Padding.pb_3, Padding.pl_2, Padding.pr_2]}
          >
            {!helpers.isWeb() && (
              <View
                style={[
                  Padding.pt_2,
                  Padding.pb_2,
                  {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  },
                ]}
              >
                <View style={{ flex: 1 }}>
                  <Text
                    style={[
                      userProfile.planName,
                      !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                      darkModeOption && { color: Colors.lightWhite },
                    ]}
                  >
                    Notifications
                  </Text>
                  <Text
                    style={[
                      userProfile.heading,
                      { fontWeight: "400", color: "#757575" },
                      darkModeOption && { color: Colors.lineColor },
                    ]}
                  >
                    {notificationSubscription.subscribed
                      ? "Turn off your app notifications"
                      : "Turn on your app notifications"}
                  </Text>
                </View>
                <View style={[Flex.row, Flex.alignCenter, Flex.justifyBetween]}>
                  <CustomSwitch
                    item={notificationSubscription}
                    handleClick={() =>
                      this.checkNotificationPermission(notificationSubscription)
                    }
                  />
                </View>
              </View>
            )}
          </View>

          {!isAdmin && (
            <TouchableOpacity
              disabled={this.state.logoutDisable}
              onPress={() => {
                this.setState({ logoutDisable: true });
                this.logout();
                this.setDarkMode("off");
              }}
            >
              <View
                style={[
                  Padding.pl_2,
                  Padding.pr_2,
                  Flex.row,
                  Flex.alignCenter,
                  Flex.justifyBetween,
                  { backgroundColor: "#FFF1EE", height: 60 },
                  darkModeOption && { backgroundColor: "#2D2D2D" },
                  helpers.isIOSDevice() && {marginBottom: 100} 
                ]}
              >
                <View style={[Flex.alignStart]}>
                  <Text
                    style={[
                      Typo.h5,
                      Helpers.textUppercase,
                      Margin.mt_0,
                      Margin.mb_0,
                      {
                        color: "#f00",
                        fontSize: 18,
                        fontFamily: FontFamily.medium,
                        fontWeight: FontWeight.medium,
                      },
                      darkModeOption && { color: Colors.brandColor1 },
                    ]}
                  >
                    Logout
                  </Text>
                </View>
                <CustomImage
                  require={true}
                  source={logoutIcon}
                  style={{ width: 22, height: 22 }}
                  webStyle={{ width: 22, height: 22 }}
                />
              </View>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }

  renderWebPage = () => {
    return <>{this.renderPageContent()}</>;
  };

  renderAppPage = () => {
    return <ScrollView>{this.renderPageContent()}</ScrollView>;
  };

  render() {
    const { pageLoader, showErrorModal } = this.state;
    const webView = helpers.isWeb();
    return (
      <View>
        {pageLoader
          ? this.showPageLoader()
          : webView
            ? this.renderWebPage()
            : this.renderAppPage()}
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigation.navigate("/")}
            tryAgain={() => console.log("/")}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { removeUserToken, saveDarkMode, darkModeToggle },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(UserProfile));
