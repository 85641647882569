import React, { Component } from 'react';
import { Text, View, ScrollView, ActivityIndicator, Platform } from 'react-native';
import { Flex, Padding, Margin, button, orderData, FontFamily, FontWeight, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import Button from '../button';
import Cookies from "universal-cookie";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeUserToken } from '../../../redux/User/user.actions';
import apiHelpers from "../../utils/api-helpers";
import CustomImage from '../custom-image';
import ButtonGradient from '../button-gradient';
import OrderView from '../order-view';
import ContentLoader from '../content-loader';
import GiftSubscriptionBox from '../../components/gift-subscription-box';
import AuthenticationWrapper from '../authentication-wrapper';

const cookies = new Cookies();

const plCreditsIcon = getImagePath('icons/plCredits.png');
const plCreditsIconDark = getImagePath('icons/plCreditsDark.png');
const goldIcon = getImagePath('icons/goldcoincredit.png');
const gift_box_two = getImagePath('icons/gift_pl_two.png');

const PrivilegeCreditGift = {
    "id": "privilege_credits",
    "name": "Gift a story",
    "tmc_group": "",
    "billing_enitity_type": "adhoc",
    "invoice_name": "Gift a story",
    "description": {
        "access_desc": [
            "As an Annual Subscriber, you can gift stories worth a total of 3 privilege credits",
            "Credits reset:",
            "Can be used to gift any long story, newsletter of your choice"
        ],
    },
    "is_popular": false,
    "recommended_plan": false,
    "discount": {},
    "is_free_privilege_credits": true,
    "is_story_gift": true
}

export class PrivilegedCredits extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const {isAuthenticated, fetchToken} = this.props.auth
        let token = webView ? (isAuthenticated?fetchToken:false) : this.props.user?.userData?.data?.token;
        this.state = {
            token: (token == undefined || token == '') ? false : token,
            enableBtn: false,
            credits: this.props.params ? this.props.params.credits : false,
            ordersData: false,// []
            pageLoader: true,
            creditsLoader: true
        }
    }

    componentDidMount = () => {
        // if (this.state.pageLoader) {
        //     this.getUserProfile();
        // }
        this.getOrdersData();
        this.getGiftingOrdersData();
        this.getSubscriptionPlans();
        this.getUserProfile();
    }

    getUserProfile = () => {
        const { token } = this.state;
        let endpoint = `/profile`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            request_group: ["credits", "subscription", "profile"]
        }
        if (isAdmin) {
            body = {
                request_group: ["credits", "subscription", "profile"],
                is_admin: true,
                reference_id: referenceId
            }
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'profile_info') {
                    this.setState({
                        credits: res.data?.credits?.privileged_credits ? res.data.credits.privileged_credits.available : 0,
                        enableBtn: (res.data?.subscription?.disable_buy_credits || (res.data?.team_subscription?.is_team_member && res.data?.team_subscription?.disable_buy_credits)) ? false : true,
                        pageLoader: false,
                        creditsLoader: false,
                        userSubscription: res.data.subscription,
                        userProfile: res.data
                    });
                    //get orders
                    //set blank array if no orders
                } else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({
                            credits: 0,
                            pageLoader: false,
                            creditsLoader: true
                        });
                    }
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                this.setState({
                    credits: 0,
                    pageLoader: false,
                    creditsLoader: true
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getGiftingOrdersData = () => {
        const { token } = this.state;
        let endpoint = `/transactions-list`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            limit: 3,
            sort: {
                on: "createdAt",
                by: "desc"
            },
            filters: {
                type: ["credits", "adhoc"],
                order_action_group: ["send_free_credits"]
            }
        }
        if (isAdmin) {
            body = {
                limit: 3,
                sort: {
                    on: "createdAt",
                    by: "desc"
                },
                filters: {
                    type: ["credits", "adhoc"],
                    order_action_group: ["send_free_credits"]
                },
                is_admin: true,
                reference_id: referenceId
            }
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'transactions') {
                    let orders_data = Array.isArray(res.data) ? res.data : []
                    this.setState({ giftingOrdersData: orders_data });
                }
                else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({ showErrorModal: true });
                    }
                }
            })
            .catch((error) => {
                this.setState({ showErrorModal: true });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getOrdersData = () => {
        const { token } = this.state;
        let endpoint = `/transactions-list`;
        const { isAdmin, referenceId } = this.props;
        let body = {
            limit: 3,
            sort: {
                on: "createdAt",
                by: "desc"
            },
            filters: {
                type: ["credits", "adhoc", "gift_credits"],
                order_action_group: ["buy_credits", "received_gift_credits", "received_free_credits"]
            }
        }
        if (isAdmin) {
            body = {
                limit: 3,
                sort: {
                    on: "createdAt",
                    by: "desc"
                },
                filters: {
                    type: ["credits", "adhoc", "gift_credits"],
                    order_action_group: ["buy_credits", "received_gift_credits", "received_free_credits"]
                },
                is_admin: true,
                reference_id: referenceId
            }
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success && res.code == 'transactions') {
                    let orders_data = Array.isArray(res.data) ? res.data : []
                    this.setState({ ordersData: orders_data });
                }
                else {
                    if (isAdmin) {
                        if (this.props.setNotAuthorized) {
                            this.props.setNotAuthorized();
                        }
                    } else {
                        this.setState({ showErrorModal: true });
                    }
                }
            })
            .catch((error) => {
                this.setState({ showErrorModal: true });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getSubscriptionPlans = (profileData) => {
        const { token } = this.state;
        let endpoint = `/plans/default`;
        let body = {
            filters: {
                tmc_group: ["credit"]
            }
        }
        if (profileData && profileData.subscription?.plan) {
            body.filters.plan_gte = profileData.subscription.plan.id;
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true, token)
            .then((res) => {
                if (res.success) {
                    this.setState({
                        plansData: res.data.plans,
                        country: res.data.country,
                        pricingPlanRefreshKey: !this.state.pricingPlanRefreshKey,
                    });
                } else {
                    console.log('Error=>>', res);
                    this.setState({
                        showErrorModal: true,
                    });
                }
            })
            .catch((error) => {
                console.log('Error=>>', error);
                this.setState({
                    showErrorModal: true,
                });
            });
    }

    renderPageContent = () => {
        let { ordersData, credits, token, plansData, country, enableBtn, creditsLoader, userSubscription, userProfile, giftingOrdersData } = this.state;

        const { isAdmin, referenceId } = this.props;

        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={{ marginBottom: helpers.isDesktop() ? 150 : 50 }}>

                {/* Gift UI */}
                {userSubscription && userSubscription.plan && userProfile && (userProfile.free_credits_available != undefined && userProfile.free_credits_available != null) &&
                    <View style={[Padding.ph_2]}>
                        {/* <Text style={[Margin.mt_2, Margin.mb_2, { textAlign: 'center', fontSize: 22, lineHeight: 33, fontFamily: FontFamily.abrilFatface, letterSpacing: 0.3 }, darkMode && { color: Colors.lightWhite }]}>Gift Privileged Credits</Text>
                        <Text style={[{ fontSize: 14, lineHeight: 21, letterSpacing: 0.3, fontFamily: FontFamily.regular, color: 'rgba(0, 0, 0, 0.7)', textAlign: 'center', marginBottom: 8 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>As an Annual Subscriber you can gift <Text style={{ fontFamily: FontFamily.bold, fontWeight: '700' }}>upto 5</Text> privilege credits each month to your friends and family.</Text>
                        <Text style={[{ fontSize: 14, lineHeight: 21, letterSpacing: 0.3, fontFamily: FontFamily.regular, color: 'rgba(0, 0, 0, 0.7)', textAlign: 'center', marginBottom: 35 }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Credits reset on: <Text style={{ fontFamily: FontFamily.bold, fontWeight: '700' }}>{helpers.ordinal(parseInt(new Date(userSubscription.end_date).getDate()))} every month</Text>.</Text> */}
                        <View style={[Margin.mt_5]}>
                            <GiftSubscriptionBox
                                darkMode={darkMode}
                                icon={gift_box_two}
                                plan={PrivilegeCreditGift}
                                subscribeToPlan={(giftCredits) => this.props.openGiftCardModal(PrivilegeCreditGift.id, PrivilegeCreditGift, giftCredits)}
                                userSubscription={userSubscription}
                                freeCreditsAvailable={userProfile?.free_credits_available}
                                plCredits={true}
                                sidebar={true}
                                resetData={` ${helpers.ordinal(parseInt(new Date(userSubscription.end_date).getDate()))} every month`}
                            />
                        </View>

                        <View>
                            {(giftingOrdersData) && giftingOrdersData.length != 0 &&
                                <>
                                    <Text style={[{ fontSize: 18, lineHeight: 21, fontFamily: FontFamily.medium, fontWeight: FontWeight.medium, textTransform: 'uppercase', marginBottom: helpers.isDesktop() ? 20 : 15 }, darkMode && { color: Colors.lightWhite }]}>RECENT GIFTING HISTORY</Text>
                                    {(giftingOrdersData.length == 0) ?
                                        <Text style={[orderData.payMethod, darkMode && { color: Colors.lightWhite }]}>You have no past transactions. Buy now to get access to our premium stories and newsletters</Text>
                                        :
                                        giftingOrdersData.map((order, index) => {
                                            return (
                                                <OrderView order={order} token={token} index={index} key={index} darkMode={darkMode} isAdmin={isAdmin} referenceId={referenceId} />
                                            )
                                        })
                                    }
                                </>
                            }
                        </View>
                    </View>
                }


                <View style={{ backgroundColor: darkMode ? '#EDDD84' : '#FFF8D6', marginBottom: helpers.isDesktop() ? 40 : 30, position: 'relative', overflow: 'hidden' }}>
                    <CustomImage
                        source={darkMode ? plCreditsIconDark : plCreditsIcon}
                        require={true}
                        style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%' }}
                        webStyle={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%' }}
                        altText={'Credits'}
                    />
                    <View style={[Padding.ph_2]}>
                        <Text style={[Margin.mt_3, Margin.mb_15, { textAlign: 'center', fontSize: 22, lineHeight: 33, fontFamily: FontFamily.abrilFatface, letterSpacing: 0.3 }]}>Privilege Credits</Text>
                        {
                            creditsLoader ?
                                <View style={{ height: 102 }}></View>
                                :
                                <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, Margin.mb_3]}>
                                    <CustomImage
                                        source={goldIcon}
                                        require={true}
                                        style={{ width: 40, height: 40 }}
                                        webStyle={{ width: 'auto', height: 47 }}
                                        altText={'Credits'}
                                    />
                                    <Text style={{ fontSize: 60, lineHeight: 72, color: '#FF9302', fontWeight: FontWeight.medium, fontFamily: FontFamily.medium, marginLeft: 15 }}>{credits}</Text>
                                </View>
                        }
                        {enableBtn && Platform.OS != 'ios' &&
                            <>
                                <Text style={[{ fontSize: 14, lineHeight: 21, letterSpacing: 0.3, fontFamily: FontFamily.regular, color: 'rgba(0, 0, 0, 0.7)', textAlign: 'center', marginBottom: 30 }, darkMode && { color: "rgba(0, 0, 0, 0.7)" }]}>Buy Privilege credits to read premium stories</Text>
                                <ButtonGradient
                                    title={'BUY MORE CREDITS'}
                                    onClick={() => this.props.showSubscriptionPlan('credits', plansData.credit[0], country)}
                                    rounded={false}
                                    disable={(!isAdmin && enableBtn && plansData?.credit[0] != undefined) ? false : true}
                                    rootStyle={{
                                        btnWrapper: [button.primaryGradient, Margin.mb_3],
                                        btnText: [button.primaryGradientText, { fontSize: 16, fontWeight: '400' }],
                                    }}
                                    darkMode={darkMode}
                                />
                            </>
                        }
                    </View>
                </View>

                <View style={[Padding.ph_2]}>
                    {(ordersData) &&
                        <>
                            <Text style={[{ fontSize: 18, lineHeight: 21, fontFamily: FontFamily.medium, fontWeight: FontWeight.medium, textTransform: 'uppercase', marginBottom: helpers.isDesktop() ? 20 : 15 }, darkMode && { color: Colors.lightWhite }]}>PURCHASE HISTORY</Text>
                            {(ordersData.length == 0) ?
                                <Text style={[orderData.payMethod, darkMode && { color: Colors.lightWhite }]}>You have no past transactions. Buy now to get access to our premium stories and newsletters</Text>
                                :
                                ordersData.map((order, index) => {
                                    return (
                                        <OrderView order={order} token={token} index={index} key={index} darkMode={darkMode} isAdmin={isAdmin} referenceId={referenceId} />
                                    )
                                })
                            }
                        </>
                    }
                </View>
            </View >
        )
    }


    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <ContentLoader darkMode={darkMode} />
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {
        return (
            <>
                <ScrollView style={{ flex: 1 }}>
                    {this.renderPageContent()}
                </ScrollView>
            </>
        )
    }

    render() {
        const webView = helpers.isWeb();
        let { showErrorModal, pageLoader } = this.state;
        return (
            <View>
                {
                    pageLoader ?
                        this.showPageLoader()
                        :
                        (webView
                            ? this.renderWebPage()
                            : this.renderAppPage()
                        )
                }
            </View>
        );
    }

}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(PrivilegedCredits));
