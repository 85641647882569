import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  newsletter,
  FontFamily,
  Colors,
  Typo,
  featuredArticle,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import moment from "moment";
import apiHelpers from "../../utils/api-helpers";
import PremiumTag from "../premium-tag";
const newsletterImg = getImagePath("img/things-change.png");
const arrowRight = getImagePath("icons/arrow-right-orange.png");

export class RecentNewsletterListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      free: true,
    };
    this.isUserSubscriptionActive = localStorage.getItem(
      "isUserSubscriptionActive"
    );
    this.userSubscription = localStorage.getItem("userSubscription");
  }

  componentDidMount = () => {
    let endpoint = `/story/${this.props.newsLetter.slug}`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, this.props.token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
          console.log("access_denied");
        }
        if (res.success) {
          this.setState({ free: res.data.is_free });
        }
      })
      .catch((error) => {
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  render() {
    let { newsLetter, darkMode } = this.props;
    console.log(this.state.free, newsLetter.slug, "priya");
    let webView = helpers.isWeb();
    let viewBtnProps, authorBtnClick;
    if (webView) {
      viewBtnProps = {
        to: "/" + newsLetter.category.slug + "/" + newsLetter.slug,
      };
      if (newsLetter.author[0].name) {
        authorBtnClick = {
          to: "/writers/" + newsLetter.author[0].slug,
        };
      }
    } else {
      viewBtnProps = {
        onPress: () => this.props.navigateToSinglePost(newsLetter.slug),
      };
    }

    return (
      <>
        <TouchableOpacityLink {...viewBtnProps}>
          <View
            style={[
              helpers.isDesktop() && Flex.row,
              // helpers.isDesktop() && Flex.alignCenter,
              helpers.isDesktop() && Margin.mb_9,
              !helpers.isDesktop() && {
                paddingHorizontal: 20,
                paddingBottom: 27,
              },
              ,
              !helpers.isWeb() && newsletter.appWrapper,
              darkMode && {
                backgroundColor: Colors.darkBlackColor,
                shadowColor: "rgba(0, 0, 0, 0.80)",
              },
            ]}
          >
            <View
              style={[
                helpers.isDesktop() && { width: 350, alignItems: "center" },
                !helpers.isWeb() && {},
              ]}
            >
              {!helpers.isDesktop() && (
                <Text
                  style={[
                    featuredArticle.newslettermain,
                    helpers.isDesktop() && featuredArticle.titleDesktop,
                    {
                      fontFamily: FontFamily.medium,
                      letterSpacing: 0.3,
                      fontWeight: "600",
                      fontSize: helpers.isDesktop() ? 20 : 13,
                      lineHeight: helpers.isDesktop() ? 42 : 15.6,
                    },
                    {
                      textAlign: "left",
                      lineHeight: helpers.isDesktop() ? 42 : 15.6,
                      marginBottom: helpers.isDesktop() ? 2 : 8,
                      textTransform: "uppercase",
                    },
                    !helpers.isWeb() && { fontFamily: FontFamily.bold },
                    darkMode && { color: "rgba(255,255,255,0.87)" },
                  ]}
                >
                  {newsLetter.newsletter.name}
                </Text>
              )}
              <CustomImage
                source={newsLetter.featured_image.url}
                require={false}
                style={{ width: "100%", height: 200 }}
                resizeMode={"cover"}
                webStyle={{
                  height: helpers.isDesktop() ? "auto" : "auto",
                  objectFit: "contain",
                  width: "100%"
                }}
                altText={newsLetter.name}
              />
              {darkMode && (
                <View
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(18, 18, 18, 0.05)",
                  }}
                ></View>
              )}
              {!this.state.free && (
                <View
                  style={{
                    position: "absolute",
                    top: helpers.isDesktop() ? 10 : 30,
                    right: 0,
                    width: "25%",
                    height: 20,
                  }}
                >
                  {/* <Text style={{ fontFamily: FontFamily.abrilFatface, color: '#ffffff', textAlign: 'center', letterSpacing: 1 }}>Premium</Text>
                   */}
                  {/* {this.isUserSubscriptionActive == "0" ||
                  this.isUserSubscriptionActive == null ? (
                    <>{!newsLetter.is_free && <PremiumTag />}</>
                  ) : null} */}
                </View>
              )}
            </View>
            <View
              style={[
                { flex: 1 },
                helpers.isDesktop() && { paddingLeft: 30 },
                !helpers.isDesktop() && { marginTop: 20 },
              ]}
            >
              <View style={[!helpers.isDesktop() && { paddingBottom: 5 }]}>
                {helpers.isDesktop() && (
                  <Text
                    style={[
                      featuredArticle.newslettermain,
                      helpers.isDesktop() && featuredArticle.titleDesktop,
                      {
                        letterSpacing: 1,
                        fontWeight: "600",
                        fontSize: 19,
                        lineHeight: 23,
                        textTransform: "uppercase",
                        fontFamily: FontFamily.regular,
                      },
                      { textAlign: "left", lineHeight: 22.8, marginBottom: 8 },
                      !helpers.isWeb() && { fontFamily: FontFamily.regular },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {newsLetter.newsletter.name}
                  </Text>
                )}
                <Text
                  style={[
                    featuredArticle.newslettertitle,
                    helpers.isDesktop() && featuredArticle.titleDesktop,
                    // !helpers.isDesktop() && { marginBottom: 16 },
                    {
                      fontFamily: "PlayfairDisplay-Bold",
                      fontWeight: "600",
                      fontSize: helpers.isDesktop() ? 30 : 24,
                      lineHeight: helpers.isDesktop() ? 44.4 : 35.52,
                    },
                    { textAlign: "left" },
                    !helpers.isWeb() && { fontFamily: "PlayfairDisplay-Bold" },
                    darkMode && { color: "rgba(255,255,255,0.87)" },
                    helpers.isWeb() && {
                      marginLeft: helpers.isDesktop() ? 0 : 0,
                    },
                  ]}
                >
                  {newsLetter.title}
                </Text>
                <Text
                  style={[
                    newsletter.title,
                    !helpers.isDesktop() && {
                      paddingBottom: 10,
                      fontSize: 14,
                      lineHeight: 21,
                    },
                    helpers.isDesktop() && { fontSize: 18, lineHeight: 30 },
                    darkMode && { color: Colors.lightWhite },
                    helpers.isWeb() && {
                      marginLeft: helpers.isDesktop() ? 0 : 0,
                    },
                  ]}
                >
                  {" "}
                  {newsLetter.strapline != ""
                    ? newsLetter.strapline
                    : helpers.trimWord(newsLetter.excerpt)}
                </Text>
                <View
                  style={[
                    newsletter.lineBreak,
                    Margin.mt_0,
                    Margin.mb_1,
                    helpers.isDesktop() && [Margin.mb_2, Margin.mt_1],
                    helpers.isWeb() && {
                      marginLeft: helpers.isDesktop() ? 0 : 0,
                    },
                  ]}
                ></View>
              </View>
              <View>
                <View
                  style={[
                    postSingle.authorDetailsWrap,
                    Flex.alignCenter,
                    helpers.isDesktop() && Flex.justfiyCenter,
                    helpers.isDesktop() && Flex.justifyStart,
                    Flex.FlexWrap,
                    helpers.isWeb() && {
                      marginLeft: helpers.isDesktop() ? 0 : 0,
                    },
                  ]}
                >
                  {
                    <TouchableOpacityLink {...authorBtnClick}>
                      <View
                        style={[
                          postSingle.authorDetailsWrap,
                          helpers.isDesktop() && Flex.alignCenter,
                          Flex.justfiyCenter,
                          helpers.isDesktop() && Flex.justifyStart,
                        ]}
                      >
                        <View
                          style={[
                            postSingle.authorPicWrap,
                            {
                              width: helpers.isDesktop() ? 31 : 22,
                              height: helpers.isDesktop() ? 31 : 22,
                            },
                          ]}
                        >
                          <CustomImage
                            source={newsLetter.author[0].profile_image}
                            require={false}
                            webStyle={{
                              width: helpers.isDesktop() ? 31 : 22,
                              height: helpers.isDesktop() ? 31 : 22,
                              borderRadius: 100,
                            }}
                            resizeMode={"contain"}
                            style={{
                              width: helpers.isDesktop() ? 31 : 22,
                              height: helpers.isDesktop() ? 31 : 22,
                              borderRadius: 100,
                            }}
                            altText={"name"}
                          />
                        </View>
                        <View style={[Padding.pl_1]}>
                          <Text
                            style={[
                              postSingle.authorName,
                              helpers.isDesktop() &&
                              postSingle.authorNameDesktop,
                              !helpers.isDesktop() && {
                                fontSize: 14,
                                lineHeight: 16,
                                fontWeight: "400",
                              },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                            ]}
                          >
                            {newsLetter.author[0].name}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacityLink>
                  }
                  {helpers.isDesktop() && newsLetter.author[1] && (
                    <>
                      <View
                        style={[
                          featuredArticle.dotSeprator,
                          { marginBottom: 0 },
                        ]}
                      />
                      <TouchableOpacityLink {...authorBtnClick}>
                        <View
                          style={[
                            postSingle.authorDetailsWrap,
                            Flex.alignCenter,
                            Flex.justfiyCenter,
                            helpers.isDesktop() && Flex.justifyStart,
                          ]}
                        >
                          <View
                            style={[
                              postSingle.authorPicWrap,
                              { width: 35, height: 35 },
                            ]}
                          >
                            <CustomImage
                              source={newsLetter.author[1].profile_image}
                              require={false}
                              webStyle={{
                                width: 31,
                                height: 31,
                                borderRadius: 100,
                              }}
                              resizeMode={"contain"}
                              style={{
                                width: 31,
                                height: 31,
                                borderRadius: 100,
                              }}
                              altText={"name"}
                            />
                          </View>
                          <View style={[Padding.pl_1]}>
                            <Text
                              style={[
                                postSingle.authorName,
                                helpers.isDesktop() &&
                                postSingle.authorNameDesktop,
                                !helpers.isDesktop() && { fontSize: 15 },
                                darkMode && { color: "rgba(255,255,255,0.87)" },
                              ]}
                            >
                              {newsLetter.author[1].name}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacityLink>
                    </>
                  )}
                  {!helpers.isDesktop() && newsLetter.author.length > 1 && (
                    <>
                      <View
                        style={[
                          featuredArticle.dotSeprator,
                          { marginBottom: 0 },
                        ]}
                      />
                      <TouchableOpacityLink>
                        <View
                          style={[
                            postSingle.authorDetailsWrap,
                            Flex.alignCenter,
                            Flex.justfiyCenter,
                            helpers.isDesktop() && Flex.justifyStart,
                          ]}
                        >
                          <View
                            style={[
                              postSingle.authorPicWrapmob,
                              {
                                width: 25,
                                height: 24,
                                backgroundColor: "#F3F5FF",
                              },
                            ]}
                          >
                            <Text
                              style={{
                                fontFamily: "Matteo-regular",
                                color: "#907CFF",
                                fontSize: 14,
                              }}
                            >
                              + {newsLetter.author.length - 1}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacityLink>
                    </>
                  )}
                  {helpers.isDesktop() && (
                    <View
                      style={[featuredArticle.dotSeprator, { marginTop: 0 }]}
                    />
                  )}
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacityLink>
      </>
    );
  }
}

export default RecentNewsletterListing;
