import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  Linking,
  TouchableWithoutFeedback,
  Modal as ReactModal,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  button,
  subscribePlans,
  Colors,
  FontFamily,
  FontWeight,
  postSingle,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import PageBreak from "../page-break";
import CustomImage from "../custom-image";
import ButtonGradient from "../button-gradient";
import ActionBox from "../action-box";
import SubscriptionPlans from "../subscription-plans";
import Modal from "../modal";
import Button from "../button";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
import ContentLoader from "../content-loader";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import AuthenticationWrapper from "../authentication-wrapper";
import { ChargebeeController } from "../../utils/chargebee";


const defaultCompany = getImagePath("icons/default-company-logo.png");
const defaultCompanyLight = getImagePath(
  "icons/default-company-logo-white.png"
);
const cookies = new Cookies();
const elementBG = getImagePath("img/welcome_bg.png");
const checkIcon = getImagePath("icons/check.png");

const studenOrangeIcon = getImagePath("icons/studenOrangeIcon.png");
const giftOrangeIcon = getImagePath("icons/giftOrangeIcon.png");
const accesOrangeIcon = getImagePath("icons/accesOrangeIcon.png");
const greenTick = getImagePath("icons/green-tick.png");
const crossIconRed = getImagePath("icons/cross-icon-red.png");
const lockIcon = getImagePath("icons/lock-icon.svg")
const lockIconDark = getImagePath("icons/lock-icon-dark.svg")
const darkTick = getImagePath("icons/dark-tick.svg")

const corporateData = {
  id: "team-plan-page",
  name: "Team",
  invoice_name: "Team",
  description: {
    access_desc: [
      "Read all the latest stories.",
      "Complete access to archives.",
      "All our subscriber-only newsletters, weekly and fortnightly.",
      "Read us on your mobile anytime with our subscriber-only app.",
    ],
  },
  is_corporate: true,
};

export class SubscriptionPricingPlan extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    this.state = {
      token: tokenSet ? token : false,
      pageLoader: this.props.plansData ? false : true,
      showErrorModal: false,
      plansData: { individual: [] },
      userSubscription: this.props.planPage
        ? this.props.userSubscription
        : false,
      teamSubscription: this.props.planPage
        ? this.props.teamSubscription
        : false,
      country: this.props.planPage ? this.props.country : false,
      creditPlan: this.props.planPage ? this.props.creditPlan : false,
      isButtonClicked: false,
      cb_handle: null,
      showButtonLoader: false,
      userSubscriptionExpired: false,
      locationInfo: false,
      individualPlanData: [],
    };
  }

  componentWillUnmount = () => { };

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentDidMount = () => {
    console.log("SubscriptionPricingPlan componentDidMount props: ", this.props)
    ChargebeeController.activatedController().then((cb_handle) => {
      this.setState({ cb_handle: cb_handle });
      let countryInfo = null;
      if (
        cb_handle?.instance?.billingInfo?.billing_platform === "chargebee_india"
      ) {
        countryInfo = {
          code: "IN",
          name: "India",
        };
      } else if (cb_handle?.instance?.billingInfo) {
        countryInfo = {
          code: "US",
          name: "USA",
        };
      }

      if (countryInfo) {
        this.setState({
          locationInfo: { countryInfo: countryInfo },
        });
      }
    });

    if (this.props.planPage) {
      this.getSubscriptionPlans();
      if (this.state.token) {
        this.getUserInfo();
      }
    } else {
      const { token } = this.state;
      if (token) {
        this.getUserInfo();
      } else {
        if (this.props.setModalTitle) {
          this.props.setModalTitle("Select Plan");
        }
        if (this.props.isAdmin) {
          this.setState({
            plansData: { individual: [] },
            pageLoader: false,
          });
        } else {
          this.getSubscriptionPlans();
        }
        this.getLocationInfo();
      }
    }
    if (!this.state.custom_user_id) {
      this.setAuthToken()
    }
    this.getIndividualPlanData()
  };

  getLocationInfo = () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  };

  componentDidUpdate = () => {
    if (this.props.planPage) {
      //
    } else {
      let webView = helpers.isWeb();
      const { isAuthenticated, fetchToken } = this.props.auth;
      let token = webView
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.user?.userData?.data?.token;
      if (
        !(
          this.props.searchQuery &&
          (this.props.searchQuery.includes("subscribe=") ||
            this.props.searchQuery.includes("buy-credits=true"))
        ) &&
        ((token && token != this.state.token) ||
          ((token == undefined || token == "") && this.state.token != false))
      ) {
        this.setState(
          {
            token: token == undefined || token == "" ? false : token,
            pageLoader: true,
          },
          () => {
            if (this.state.token) {
              this.getUserInfo(true);
            } else {
              if (this.props.setModalTitle) {
                this.props.setModalTitle("Select Plan");
              }
              if (this.props.isAdmin) {
                this.setState({
                  plansData: { individual: [] },
                  pageLoader: false,
                });
              } else {
                this.getSubscriptionPlans();
              }
            }
          }
        );
      }
    }
  };

  getIndividualPlanData = (profileData) => {
    const { token } = this.state;
    let endpoint = `/plans/individual/IN`;
    if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN") {
      endpoint = `/plans/individual/${this.state.locationInfo?.countryInfo?.code}`
    }
    let body = {
      filters: {
        tmc_group: ["individual"]
      },
      rank_sort: "DESC"
    }

    if (profileData && profileData?.subscription?.plan) {
      body.filters.plan_gte = profileData.subscription.plan.id;
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.plans) {
          console.log("Individual plans data: ", res)
          this.setState({
            individualPlanData: res,
            pageLoader: false
          });
        } else {
          console.log('individual Error 1=>>', res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log('individual Error 2=>>', error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });

  }


  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      }
    }
  };

  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getUserInfo = (scroll) => {
    this.scrollToTop(scroll);
    const { token } = this.state;
    let endpoint = `/profile`;
    const { isAdmin, referenceId } = this.props;
    let body = {
      request_group: ["subscription", "profile"],
    };
    if (isAdmin) {
      body = {
        request_group: ["subscription", "profile"],
        is_admin: true,
        reference_id: referenceId,
      };
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
          return false;
        }
        if (res.success) {
          this.setState({
            userSubscription:
              !res.data.subscription ||
                JSON.stringify(res.data.subscription) == "{}"
                ? false
                : res.data.subscription,
            teamSubscription:
              !res.data.team_subscription ||
                JSON.stringify(res.data.team_subscription) == "{}"
                ? false
                : res.data.team_subscription,
            userSubscriptionExpired:
              (res.data.team_subscription &&
                !this.isObjectEmpty(res.data.team_subscription)) ||
                (res.data.subscription &&
                  !this.isObjectEmpty(res.data.subscription)) ||
                (res.data.expired_subscription &&
                  !this.isObjectEmpty(res.data.team_subscription))
                ? true
                : false,
            userProfile: res.data,
          });
          if (this.props.setModalTitle) {
            if (
              !res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}"
            ) {
              this.props.setModalTitle("Select Plan");
            } else {
              this.props.setModalTitle("My Plan");
            }
          }
          if (isAdmin) {
            this.setState({
              plansData: { individual: [] },
              pageLoader: false,
            });
          } else {
            this.getSubscriptionPlans(res.data);
          }
        } else {
          if (this.props.setModalTitle) {
            this.props.setModalTitle("Select Plan");
          }
          console.log("Error=>>", res);
          if (isAdmin) {
            if (this.props.setNotAuthorized) {
              this.props.setNotAuthorized();
            }
          } else {
            this.setState({
              showErrorModal: true,
              pageLoader: false,
            });
          }
        }
      })
      .catch((error) => {
        if (this.props.setModalTitle) {
          this.props.setModalTitle("Select Plan");
        }
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  showChargbeePage = (callback, ...params) => {
    if (callback) callback(...params);
  };

  isEmptyDict = (obj) => {
    return Object.keys(obj).length === 0;
  };

  CB__HostedPage = (
    actionType,
    existingSubscriptionId,
    selectedSubscribtionSlug,
    quantity,
    hostedObjectParams = null
  ) => {
    let shouldUpdateUrl =
      ["/pricing", "/team-subscription", "/student-subscription"].includes(
        window.location.pathname
      ) && helpers.isDesktop()
        ? true
        : false;
    const urlParams = helpers.getURLParamDict(window.location.href);
    const couponIdList = urlParams.coupon_ids
      ? urlParams.coupon_ids.split(",")
      : [];

    const extraParams = {
      coupon_ids: couponIdList,
    };

    ChargebeeController.activatedController().then((cb_handle) => {
      if (cb_handle?.instance?.siteConfig?.enableRedirectMode === true) {
        window.history.pushState(
          { path: `${window.location.pathname}${window.location.hash}` },
          "",
          `${window.location.pathname}${window.location.hash}`
        );
      }

      if (actionType == "checkout_new") {
        cb_handle.hosted_page.checkout_new(
          {
            plan_id: selectedSubscribtionSlug,
            plan_quantity: quantity,
            ...extraParams,
          },
          {},
          shouldUpdateUrl
        );
      } else if (actionType == "collect_now") {
        cb_handle.hosted_page.collect_now();
      } else if (actionType == "checkout_existing") {
        cb_handle.hosted_page.checkout_existing(
          { ...hostedObjectParams, ...extraParams },
          {},
          shouldUpdateUrl
        );
      }
    });

    return;
  };

  openSubscriptionPlan = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity,
    themeData,
    paymentConfig = {},
    couponId = null
  ) => {
    this.setState({
      showSubscriptionPlanModal: true,
      selectedSubscribtionID: subscribtionID,
      paymentConfig: paymentConfig,
      couponId: couponId,
      selectedSubscribtion: selectedSubscribtion,
      country: country,
      subscribeEmail: email ? email : "",
      quantity: quantity,
      themeData: themeData,
    });
    if (
      !window.location.search.includes("subscribe=") &&
      !window.location.search.includes("buy-credits=true")
    ) {
      let pushProp = JSON.parse(JSON.stringify(window.location));
      let searchArr = window.location.search.replace("?", "").split("&");
      let queryParam =
        subscribtionID == "credits"
          ? "buy-credits=true"
          : subscribtionID == "renew-plan" ||
            subscribtionID == "renew-team-plan" ||
            selectedSubscribtion.tmc_group != "team"
            ? `subscribe=${subscribtionID}`
            : `idx=1&subscribe=${subscribtionID}&quantity=${quantity}`;
      if (searchArr.length > 0 && searchArr[0] != "") {
        pushProp.search += "&" + queryParam;
      } else {
        pushProp.search = "?" + queryParam;
      }
      this.props.history.push(pushProp);
    }

    if (subscribtionID === "team-plan-4-inr" && !window.location.search.includes("quantity=")) {
      let pushProp = JSON.parse(JSON.stringify(window.location));
      let searchArr = window.location.search.replace("?", "").split("&");
      let queryParam = `idx=1&subscribe=${subscribtionID}&quantity=${quantity}`;
      pushProp.search = "?" + queryParam;
      this.props.history.replace(pushProp);
      window.location.reload()
    }
  };

  _showSubscriptionPlan = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity = 1,
    themeData = null,
    paymentConfig = {}
  ) => {
    const urlParams = helpers.getURLParamDict(window.location.href);

    const couponId = urlParams.coupon_ids;

    if (!helpers.isDesktop()) {
      this.closeSideBar();
    }

    // this.props.setCheckoutLoaderState(false);

    if (this.state.token && this.state.userProfile == false) {
      this.getUserInfo(true, {
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        quantity,
      });
      return false;
    }
    if (subscribtionID == "renew-plan") {
      if (
        JSON.stringify(this.state.userProfile.subscription) != "{}" &&
        this.state.userProfile.subscription.can_renew
      ) {
        this.openSubscriptionPlan(
          subscribtionID,
          this.state.userProfile.subscription.plan,
          country,
          paymentConfig
        );
      }
      return false;
    }
    if (subscribtionID == "renew-team-plan") {
      if (
        JSON.stringify(this.state.userProfile.team_subscription) != "{}" &&
        this.state.userProfile.team_subscription.can_renew &&
        this.state.userProfile.team_subscription.is_team_owner
      ) {
        this.openSubscriptionPlan(
          subscribtionID,
          this.state.userProfile.team_subscription.plan,
          country
        ),
          paymentConfig;
      }
      return false;
    }
    if (
      subscribtionID == "credits" &&
      (this.state.userProfile?.subscription?.disable_buy_credits ||
        (this.state.userProfile?.team_subscription?.is_team_member &&
          this.state.userProfile?.team_subscription?.disable_buy_credits))
    ) {
      return false;
    }
    if (
      selectedSubscribtion &&
      selectedSubscribtion.tmc_group == "team" &&
      parseInt(quantity) < 2
    ) {
      return false;
    }

    if (window && window.ReactNativeWebView && !themeData) {
      try {
        themeData = cookies.get("themeData");
        if (typeof themeData == "string") {
          themeData = JSON.parse(themeData);
        }
      } catch (error) {
        this.setState({ themeDataError: error });
      }
    }
    if (
      selectedSubscribtion &&
      (selectedSubscribtion.tmc_group == "gift_plan" ||
        selectedSubscribtion.tmc_group == "gift_credit") &&
      !themeData
    ) {
      this.openGiftCardModal(
        subscribtionID,
        selectedSubscribtion,
        country,
        email
      );
      return false;
    }
    this.openSubscriptionPlan(
      subscribtionID,
      selectedSubscribtion,
      country,
      email,
      quantity,
      themeData,
      paymentConfig,
      couponId
    );
  };


  showSubscriptionOptionsCore = (
    subscribtionID,
    selectedSubscribtion,
    country,
    email,
    quantity = 1,
    themeData = null,
    paymentMethod = null,
    bankName = null
  ) => {
    const { token } = this.state;
    if (!token) {
      this._showSubscriptionPlan(
        subscribtionID,
        selectedSubscribtion,
        country,
        email,
        quantity,
        themeData,
        {}
      );
      return;
    }

    const urlParams = helpers.getURLParamDict(window.location.href);
    const forcePaymentGateway = urlParams.force_payment_gateway;
    const forceCheckoutType = urlParams.force_checkout_type;

    let endpoint = `/get-checkout-options`;

    let body = {
      planName: selectedSubscribtion.id,
      force_payment_gateway: forcePaymentGateway,
      force_checkout_type: forceCheckoutType,
      paymentMethod: paymentMethod,
      bankName: bankName,
    };
    try {
      console.log("try");
      window.webengage.track("Purchase Initiated", {
        "Type of subscription": selectedSubscribtion?.id,
        Amount: selectedSubscribtion?.price,
      });
      console.log("Purchase Initiated", {
        "Type of subscription": selectedSubscribtion?.id,
        Amount: selectedSubscribtion?.price,
      });
    } catch (err) { }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code === "access_denied") {
          this._showSubscriptionPlan(
            subscribtionID,
            selectedSubscribtion,
            country,
            email,
            quantity,
            themeData,
            {}
          );
          return;
        }
        if (!res.success || res.code !== "checkout_options") {
          console.log(
            "Assert failed (showSubscriptionPlan:/get-checkout-options):",
            res.success,
            res.code
          );
          return false;
        }
        const paymentConfig = res.data;

        /*
            Case 1: upgrade
        */
        console.log("Here ---->>>>>", "case 1 upgrade", { paymentConfig }, this.state.userProfile?.subscription?.subscription_id &&
          selectedSubscribtion.billing_enitity_type === "plan")
        if (
          this.state.userProfile?.subscription?.subscription_id &&
          selectedSubscribtion.billing_enitity_type === "plan"
        ) {
          let existingSubscriptionId =
            this.state.userProfile.subscription.subscription_id;
          if (paymentConfig.checkoutType === "CB") {
            // // apply only for advance renewal condition
            // let advance_renew = {
            //     terms_to_charge: 1,
            //     // force_term_reset: true,
            //     invoice_immediately: true,
            //     subscription: {
            //         plan_quantity: quantity,
            //     },
            // }

            let upgrade_plan = {
              subscription: {
                plan_id: selectedSubscribtion.id,
                plan_quantity: quantity,
              },
            };

            this.showChargbeePage(
              this.CB__HostedPage,
              "checkout_existing",
              existingSubscriptionId,
              selectedSubscribtion.id,
              quantity,
              upgrade_plan
            );
          } else {
            this._showSubscriptionPlan(
              subscribtionID,
              selectedSubscribtion,
              country,
              email,
              quantity,
              themeData,
              paymentConfig
            );
          }
        } else if (paymentConfig.checkoutType === "CB") {
          /*
              Case 2: new sub checkout using CB
          */
          console.log("Here ---->>>>>", "case 2 new sub checkout using CB")

          this.showChargbeePage(
            this.CB__HostedPage,
            "checkout_new",
            null,
            selectedSubscribtion.id,
            quantity
          );
        } else if (paymentConfig.checkoutType === "TMC") {
          /*
              Case 3: new sub checkout using TMC
          */
          console.log("Here ---->>>>>", "case 3 new sub checkout using TMC")
          this._showSubscriptionPlan(
            subscribtionID,
            selectedSubscribtion,
            country,
            email,
            quantity,
            themeData,
            paymentConfig
          );
        }
      })
      .catch((error) => {
        console.log("Error-catch(showPayment:/get-checkout-options):", error);
      });
  };

  showActivePlanMob = () => {
    if (!(this.state?.userSubscription?.status === 'active')) return <></>
    return <View style={{ display: 'flex', alignItems: 'center', width: '90%', rowGap: 10, backgroundColor: '#FFF3E3', borderColor: '#E7D6C9', borderWidth: 1 }}>
      <View style={{ marginTop: 20 }}>
        <Text style={{ color: '#051462', fontWeight: 600, fontSize: 24, textAlign: 'center' }}>{this.state.userSubscription?.plan?.description?.display_name?.toUpperCase()}</Text>
      </View>
      <View style={{ display: this.state.userSubscription?.plan?.price > 0 ? 'flex' : 'none' }}>
        <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
          {this.state.userSubscription?.plan?.currency_code === 'INR'
            ? `₹${this.state.userSubscription?.plan?.price}`
            : `$${this.state.userSubscription?.plan?.price}`}
        </Text>
      </View>
      <View style={{ display: this.state.userSubscription?.plan?.price > 0 ? 'flex' : 'none' }}>
        <Text style={{ color: '#051462', fontWeight: 500, fontSize: 14 }}>
          At {this.state.userSubscription?.plan?.currency_code !== 'INR'
            ? `$${this.state.userSubscription?.plan?.description?.monthly_price || parseFloat(this.state.userSubscription?.plan?.chargebee_price) / 100}`
            : `₹${this.state.userSubscription?.plan?.description?.monthly_price || parseInt(this.state.userSubscription?.plan?.chargebee_price) / 100}`} / month
        </Text>
      </View>
      <View style={{ width: '80%', display: 'flex', flexDirection: 'column', columnGap: 10, justifyContent: 'center', rowGap: 10, marginTop: this.state.userSubscription?.plan?.price > 0 ? 30 : 0 }}>
        {this.state.userSubscription?.plan?.description?.access_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
          <CustomImage
            source={greenTick}
            style={{ width: 10, height: 10, objectFit: 'contain' }}
            webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
            className={'green-tick-icon'}
            altText={name}
          />
          <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
        </View>)}
      </View>
      <TouchableOpacity onPress={() => {
        if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined) {
          this.setState({
            selectedSubscribtion: {
              id: this.state.userSubscription?.plan?.id,
              billing_enitity_type: this.state.userSubscription?.plan?.billing_enitity_type,
              price: this.state.userSubscription?.plan?.price
            },
            selectedSubscribtionID: this.state.userSubscription?.plan?.id
          })
          setTimeout(() => this.proceedToCheckoutScreen(), 0)
        }
      }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#051462' }}>
        <Text style={{ fontSize: 14, fontWeight: 700, color: 'white' }}>{this.isSubscriptionUnderDunning() &&
          this.state.userSubscription?.due_since !== undefined ? "RENEW" : "YOUR CURRENT PLAN"}</Text>
      </TouchableOpacity>
      <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', marginBottom: 10 }}>
        <Text style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
          Your current plan will expire on {moment(this.state.userSubscription?.next_billing_at || this.state.userSubscription?.end_date).format('Do MMMM YYYY')}
        </Text>
      </View>
    </View>
  }

  proceedToCheckoutScreen = (paymentMode, bankName) => {

    this.setState({ showCheckoutOptions: false });
    this.setState({ paymentMethod: paymentMode, bankName: bankName });
    // Passing paymentMode and bankName to this API because the set state
    // does not happen immediately so we can't be sure if the values will be set
    console.log(
      "Here --->>>>",
      "setting the show checkout options to false",
      " setting payment method and bank name and sub id:::::::",
      this.state.selectedSubscribtionID,
      this.state.selectedSubscribtion,
      this.state.country,
      this.state.email,
      this.state.quantity,
      this.state.themeData,
      paymentMode,
      bankName
    )
    this.showSubscriptionOptionsCore(
      this.state.selectedSubscribtionID,
      this.state.selectedSubscribtion,
      this.state.country,
      this.state.email,
      this.state.selectedSubscribtionID.includes('team-plan') ? this.state.quantity : 1,
      this.state.themeData,
      paymentMode,
      bankName
    );
    setTimeout(() => this.setState({ isButtonClicked: false }), 8000)
  };

  getSubscriptionPlans = (profileData) => {
    const { token } = this.state;
    let endpoint = `/plans/default`;
    // let endpoint = `/plans/IN`;
    let body = {
      filters: {
        tmc_group: ["individual", "free", "exclusive"],
      },
      rank_sort: "ASC",
    };
    // if (profileData && profileData?.subscription?.plan) {
    //   body.filters.plan_gte = profileData.subscription.plan.id;
    // }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          // let creditPlan = res.data.plans.credit[0];
          this.setState(
            {
              plansData:
                profileData && profileData?.team_subscription?.is_team_member
                  ? { individual: [] }
                  : res.data.plans,
              country: res.data.country,
              pageLoader: false,
              creditPlan: null,
            },
            () => {
              console.log("resP:", this.state.plansData);
            }
          );
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() - 1);
      cookies.set("token", "", { path: "/", expires: cookieDate });
    } else {
      this.props.removeUserToken();
    }
    this.setState(
      {
        token: false,
        userLoggedin: false,
      },
      () => this.getSubscriptionPlans()
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (this.props.homepage) {
      return <></>;
    }
    return <ContentLoader darkMode={darkMode} />;
  };

  navigateToTeamSubscription = () => {
    if (this.props.sidePanel) {
      this.props.navigatePage("/team-subscription");
    } else {
      this.props.navigateScreen("/team-subscription");
    }
  };

  isSubscriptionUnderDunning() {
    let { cb_handle } = this.state;
    if (cb_handle?.activeSubscription?.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  navigateToTeamPage = () => {
    if (this.props.sidePanel) {
      this.props.navigatePage("/my-team");
    } else {
      this.props.navigateScreen("/my-team");
    }
  };

  renderCurrentTeamPlan = () => {
    const { plansData, teamSubscription } = this.state;
    let { darkMode, sidePanel, isAdmin, referenceId } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    let userPlan = teamSubscription.plan;
    let ownerDesc = userPlan.description.owner_desc
      ? userPlan.description.owner_desc
      : userPlan.description.access_desc;
    let memberDesc = userPlan.description.member_desc
      ? userPlan.description.member_desc
      : userPlan.description.access_desc;
    return (
      <View>
        {/*<View style={[Margin.mb_2]}>
                    <Text style={[subscribePlans.planPrice, Margin.mt_0,{fontSize: 36, lineHeight: 43}]}>{teamSubscription.team_details.seats_purchased} member{teamSubscription.team_details.seats_purchased > 1 ? 's' : ''}</Text>
                </View>*/}
        {teamSubscription && teamSubscription.is_team_owner ? (
          <View>
            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Flex.justfiyCenter,
                Margin.mb_3,
              ]}
            >
              <CustomImage
                source={
                  teamSubscription.user_info.company_logo
                    ? teamSubscription.user_info.company_logo
                    : darkModeOption
                      ? defaultCompanyLight
                      : defaultCompany
                }
                require={true}
                style={{
                  width: 28,
                  height: 28,
                  resizeMode: "cover",
                  marginRight: 10,
                }}
                webStyle={{
                  width: 28,
                  height: 28,
                  objectFit: "cover",
                  marginRight: 10,
                }}
                altText={
                  teamSubscription.user_info.company_name
                    ? teamSubscription.user_info.company_name
                    : `${teamSubscription.user_info.first_name != ""
                      ? teamSubscription.user_info.first_name
                      : teamSubscription.user_info.last_name
                    } Team`
                }
              />
              <Text
                style={[
                  {
                    fontFamily: FontFamily.abrilFatface,
                    fontSize: 20,
                    lineHeight: 24,
                  },
                  darkModeOption && { color: Colors.lightWhite },
                ]}
              >
                {teamSubscription.user_info.company_name
                  ? teamSubscription.user_info.company_name
                  : `${teamSubscription.user_info.first_name != ""
                    ? teamSubscription.user_info.first_name
                    : teamSubscription.user_info.last_name
                  } Team`}
              </Text>
            </View>

            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Margin.mb_3,
                { justifyContent: "space-evenly" },
              ]}
            >
              <View style={{ marginRight: 20, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: FontFamily.merriweather,
                    fontSize: 24,
                    lineHeight: 48,
                    color: darkModeOption
                      ? Colors.lightWhite
                      : "rgba(0, 0, 0, 0.7)",
                    letterSpacing: 0.3,
                  }}
                >
                  {teamSubscription?.plan.tmc_group == "team_credit"
                    ? teamSubscription?.team_details?.credits_available
                    : teamSubscription?.team_details?.seats_purchased}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.regular,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                  ]}
                >
                  {teamSubscription?.plan.tmc_group == "team_credit"
                    ? "Credits Available"
                    : "Seats Purchased"}
                </Text>
              </View>
              <View style={{ marginRight: 20, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: FontFamily.merriweather,
                    fontSize: 24,
                    lineHeight: 48,
                    color: darkModeOption
                      ? Colors.lightWhite
                      : "rgba(0, 0, 0, 0.7)",
                    letterSpacing: 0.3,
                  }}
                >
                  {teamSubscription?.team_details?.total_members}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.regular,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                  ]}
                >
                  Members
                </Text>
              </View>
              <View style={{ marginRight: 20, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: FontFamily.merriweather,
                    fontSize: 24,
                    lineHeight: 48,
                    color: darkModeOption
                      ? Colors.lightWhite
                      : "rgba(0, 0, 0, 0.7)",
                    letterSpacing: 0.3,
                  }}
                >
                  {teamSubscription?.team_details?.pending_invites}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.regular,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                  ]}
                >
                  Invited
                </Text>
              </View>
            </View>

            {ownerDesc && (
              <View style={[Margin.mb_3]}>
                {ownerDesc.map((itemElement, index) => {
                  return (
                    <View style={[Margin.mt_1, Margin.mb_0]}>
                      <View style={[Flex.row, { alignItems: "flex-start" }]}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                        <Text
                          style={[
                            subscribePlans.planDesc,
                            helpers.isDesktop() &&
                            subscribePlans.planDescDesktop,
                            this.props.sidePanel &&
                            subscribePlans.planDescPanel,
                            Margin.ml_1,
                            { textAlign: "left", marginBottom: 0 },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          {itemElement}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            )}

            <View>
              <ButtonGradient
                title={"MANAGE MEMBERS"}
                rounded={true}
                fullWidth={true}
                disable={isAdmin ? true : false}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradientBtn,
                    { height: 46, borderRadius: 0, margin: 0 },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: helpers.isDesktop() ? 17 : 16,
                      fontWeight: "400",
                      paddingTop: helpers.isDesktop() ? 0 : 15,
                      paddingBottom: helpers.isDesktop() ? 0 : 15,
                    },
                  ],
                }}
                onClick={() => this.navigateToTeamPage()}
                darkMode={darkModeOption}
              />

              {this.isSubscriptionUnderDunning() && (
                <ButtonGradient
                  title={
                    this.state.isButtonClicked &&
                      (this.state.showButtonLoader ||
                        this.state.cb_handle?.instance?.siteConfig
                          ?.enableRedirectMode) == true ? (
                      <>
                        <ActivityIndicator
                          size={25}
                          color={
                            this.state.showButtonLoader ? "#000000" : "#FFFFFF"
                          }
                        />
                      </>
                    ) : (
                      "Renew"
                    )
                  }
                  rounded={true}
                  fullWidth={true}
                  disable={isAdmin ? true : false}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradientBtn,
                      { height: 46, borderRadius: 0, margin: 0 },
                      {
                        marginTop: 5,
                      },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        fontWeight: "400",
                        paddingTop: helpers.isDesktop() ? 0 : 15,
                        paddingBottom: helpers.isDesktop() ? 0 : 15,
                      },
                    ],
                  }}
                  onClick={() => {
                    this.setState({ isButtonClicked: true });
                    ChargebeeController.activatedController().then(
                      (cb_handle) => {
                        cb_handle.hosted_page.collect_now();
                        this.setState({ showButtonLoader: true });
                      }
                    );
                  }}
                  darkMode={darkModeOption}
                />
              )}
            </View>
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            {memberDesc &&
              memberDesc.map((itemElement, index) => {
                return (
                  <View style={[Margin.mt_1, Margin.mb_0]}>
                    <View style={[Flex.row, { alignItems: "flex-start" }]}>
                      <CustomImage
                        source={checkIcon}
                        require={true}
                        style={{
                          width: 20,
                          height: 14,
                          resizeMode: "contain",
                          marginTop: 3,
                        }}
                        webStyle={{
                          width: 20,
                          height: 14,
                          objectFit: "contain",
                          marginTop: 3,
                        }}
                      />
                      <Text
                        style={[
                          subscribePlans.planDesc,
                          helpers.isDesktop() && subscribePlans.planDescDesktop,
                          this.props.sidePanel && subscribePlans.planDescPanel,
                          Margin.ml_1,
                          { textAlign: "left", marginBottom: 0 },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {itemElement}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        )}
      </View>
    );
  };

  renderCurrentPlan = (giftPlan) => {
    const { plansData, userSubscription } = this.state;
    let userPlan = userSubscription.plan;
    let { darkMode, sidePanel } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    return (
      <View>
        {Platform.OS != "ios" && userPlan.id != "institution-plan-inr" && (
          <>
            <View style={[Margin.mb_2]}>
              <Text
                style={[
                  subscribePlans.planPrice,
                  Margin.mt_0,
                  !helpers.isWeb() && { paddingTop: 10 },
                ]}
              >
                <Text style={[subscribePlans.symbol]}>
                  {userPlan.currency_code == "INR" ? "₹" : "$"}
                </Text>
                {userSubscription.subscription_amount_paid
                  ? userSubscription.subscription_amount_paid
                  : userSubscription.subscribed_plan_amount
                    ? userSubscription.subscribed_plan_amount
                    : userPlan.discount?.offer_price
                      ? userPlan.discount.offer_price
                      : userPlan.price}
              </Text>
            </View>

            {userPlan.description?.monthly_price && (
              <View
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center",
                  marginBottom: 25,
                }}
              >
                <View
                  style={{
                    backgroundColor: "rgba(144, 124, 255, 0.1)",
                    borderRadius: 3,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      fontFamily: FontFamily.regular,
                      color: "#907CFF",
                    }}
                  >
                    at {userPlan.currency_code == "INR" ? "₹" : "$"}
                    {userPlan.description.monthly_price}/month
                  </Text>
                </View>
              </View>
            )}
          </>
        )}
        <View style={{ flex: 1 }}>
          {userPlan.description.access_desc_new ? (
            <>
              {userPlan.description.access_desc_new.map((itemElement) => {
                return (
                  <View style={[Margin.mt_1, Margin.mb_0]}>
                    <View style={[Flex.row, { alignItems: "flex-start" }]}>
                      <View style={{ minWidth: 14 }}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                      </View>
                      <View
                        style={[
                          { flexDirection: "row", flexWrap: "wrap", flex: 1 },
                        ]}
                      >
                        {Object.keys(itemElement).map((key) => {
                          if (key.includes("text")) {
                            return (
                              <Text
                                style={[
                                  subscribePlans.planDesc,
                                  helpers.isDesktop() &&
                                  subscribePlans.planDescDesktop,
                                  this.props.sidePanel &&
                                  subscribePlans.planDescPanel,
                                  Margin.ml_1,
                                  { textAlign: "left", marginBottom: 0 },
                                  darkModeOption && {
                                    color: Colors.lightWhite,
                                  },
                                ]}
                              >
                                {itemElement[key]}
                              </Text>
                            );
                          } else {
                            let linkClick;
                            if (helpers.isWeb()) {
                              linkClick = {
                                href: itemElement[key]["href"],
                              };
                            } else {
                              linkClick = {
                                onPress: () =>
                                  Linking.openURL(itemElement[key]["href"]),
                              };
                            }
                            return (
                              <TouchableOpacityLinkExternal
                                {...linkClick}
                                newTab={true}
                              >
                                <Text
                                  style={[
                                    subscribePlans.planDesc,
                                    helpers.isDesktop() &&
                                    subscribePlans.planDescDesktop,
                                    this.props.sidePanel &&
                                    subscribePlans.planDescPanel,
                                    Margin.ml_1,
                                    {
                                      textAlign: "left",
                                      marginBottom: 0,
                                      textDecorationLine: "underline",
                                    },
                                    darkModeOption && {
                                      color: Colors.lightWhite,
                                    },
                                  ]}
                                >
                                  {itemElement[key]["title"]}
                                </Text>
                              </TouchableOpacityLinkExternal>
                            );
                          }
                        })}
                      </View>
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <>
              {userPlan.description.access_desc &&
                userPlan.description.access_desc.map((itemElement, index) => {
                  return (
                    <View style={[Margin.mt_1, Margin.mb_0]}>
                      <View style={[Flex.row, { alignItems: "flex-start" }]}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                        <Text
                          style={[
                            subscribePlans.planDesc,
                            helpers.isDesktop() &&
                            subscribePlans.planDescDesktop,
                            this.props.sidePanel &&
                            subscribePlans.planDescPanel,
                            Margin.ml_1,
                            { textAlign: "left", marginBottom: 0 },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          {itemElement}
                        </Text>
                      </View>
                    </View>
                  );
                })}
            </>
          )}
        </View>
        {!giftPlan ? (
          <>
            {userSubscription.plan.id != "institution-plan-inr" && (
              <View>
                {userSubscription.next_billing_at &&
                  userSubscription?.next_renewal?.amount_due &&
                  userSubscription.auto_collection == "on" &&
                  !userSubscription.is_cancelled &&
                  userSubscription.status != "expired" ? (
                  <>
                    {Platform.OS != "ios" && (
                      <View
                        style={[
                          Margin.mt_2,
                          Margin.mb_0,
                          Padding.pv_2,
                          {
                            borderColor: "#DDDDDD",
                            borderTopWidth: 1,
                            borderBottomWidth: 1,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            subscribePlans.planDesc,
                            helpers.isDesktop() &&
                            subscribePlans.planDescDesktop,
                            this.props.sidePanel &&
                            subscribePlans.planDescPanel,
                            Margin.mh_3,
                            { textAlign: "left", marginBottom: 0 },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          Your next payment of{" "}
                          <Text style={[{ color: "#9b7bf2" }]}>
                            <Text>
                              {userPlan.currency_code == "INR" ? "₹" : "$"}
                            </Text>
                            {userSubscription.next_renewal.amount_due}
                          </Text>{" "}
                          will be charged on{" "}
                          {moment(userSubscription.next_billing_at).format(
                            "Do MMMM YYYY"
                          )}
                        </Text>
                      </View>
                    )}
                  </>
                ) : (
                  <View
                    style={[
                      Margin.mt_2,
                      Margin.mb_0,
                      Padding.pv_2,
                      {
                        borderColor: "#DDDDDD",
                        borderTopWidth: 1,
                        borderBottomWidth: 1,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        subscribePlans.planDesc,
                        helpers.isDesktop() && subscribePlans.planDescDesktop,
                        this.props.sidePanel && subscribePlans.planDescPanel,
                        Margin.mh_3,
                        { textAlign: "left", marginBottom: 0 },
                        darkModeOption && { color: Colors.lightWhite },
                      ]}
                    >
                      {(userSubscription.status == "expired"
                        ? "Your plan has expired on "
                        : "Your plan will expire on ") +
                        moment(userSubscription.next_billing_at).format(
                          "Do MMMM YYYY"
                        )}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </>
        ) : (
          <>
            <View
              style={[
                Margin.mt_2,
                Margin.mb_0,
                Padding.pv_2,
                { borderColor: "#000000", borderTopWidth: 1 },
              ]}
            >
              <Text
                style={[
                  subscribePlans.planDesc,
                  helpers.isDesktop() && subscribePlans.planDescDesktop,
                  this.props.sidePanel && subscribePlans.planDescPanel,
                  { textAlign: "center", marginBottom: 0, fontSize: 16 },
                  darkModeOption && { color: Colors.lightWhite },
                ]}
              >
                Gifted by:{" "}
                <Text style={[{ fontWeight: "600" }]}>
                  {userSubscription?.sender_info?.name}
                </Text>{" "}
                on{" "}
                <Text style={[{ fontWeight: "600" }]}>
                  {moment(userSubscription.start_date).format("Do MMMM YYYY")}
                </Text>
              </Text>
            </View>
          </>
        )}
      </View>
    );
  };

  pushPurchasePlanInteractionEventToDataLayer = (subscription) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "purchase_plan_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in " : "guest",
      plan_price: subscription.price,
      plan_name: subscription.name?.toLowerCase(),
      CTA_text: this.state.userSubscription ? "upgrade Plan" : "select Plan",
      scroll_depth: helpers.getScrollDepth(),
    });
  };

  subscribeToPlan = (subscribtion) => {
    console.log("subscribtion:", subscribtion);
    this.pushPurchasePlanInteractionEventToDataLayer(subscribtion)
    try {
      console.log("try");
      window.webengage.track("Click Select Plan", {
        "Type of User": this.state.token
          ? this.state.userSubscription
            ? "Paid"
            : "Free"
          : "non-logged in",
        "Plan Name": subscribtion?.name,
        "Component Name": "SubscriptionPricingPlan",
      });
      console.log("success");
    } catch (err) { }

    this.props.showSubscriptionPlan(
      subscribtion?.tmc_group == "credit" ? "credits" : subscribtion.id,
      subscribtion,
      this.state.country
    );
  };

  renewPlan = () => {
    this.props.showSubscriptionPlan("renew-plan", false, this.state.country);
  };

  renewTeamPlan = () => {
    this.props.showSubscriptionPlan(
      "renew-team-plan",
      false,
      this.state.country
    );
  };

  cancelPlan = () => {
    if (this.props.sidePanel) {
      this.props.navigateScreen("accounts/cancel-subscription", {
        plan:
          this.state.userSubscription &&
            this.state.userSubscription.is_cancelled == false
            ? true
            : false,
      });
    } else {
      if (helpers.isWeb()) {
        let pushProp = JSON.parse(JSON.stringify(window.location));
        pushProp.hash = "accounts/cancel-subscription";
        this.props.navigateScreen(pushProp);
      } else {
        this.props.navigation.navigate("accounts/cancel-subscription");
      }
    }
  };

  cancelTeamPlan = () => {
    if (this.props.sidePanel) {
      this.props.navigateScreen("accounts/cancel-team-subscription", {
        plan:
          this.state.teamSubscription &&
            this.state.teamSubscription.is_cancelled == false &&
            this.state.teamSubscription.is_team_owner
            ? true
            : false,
      });
    } else {
      if (helpers.isWeb()) {
        let pushProp = JSON.parse(JSON.stringify(window.location));
        pushProp.hash = "accounts/cancel-team-subscription";
        this.props.navigateScreen(pushProp);
      } else {
        this.props.navigation.navigate("accounts/cancel-team-subscription");
      }
    }
  };

  mostPopularPlanTag = () => {
    return <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1E1B4B', position: 'relative', width: '60%', height: 30, bottom: 35, alignSelf: 'center' }}>
      <Text style={{ fontSize: 14, color: 'white', fontWeight: 500 }}>MOST POPULAR</Text>
    </View>
  }

  renderPageContent = () => {
    const { plansData, userSubscription, teamSubscription, creditPlan } =
      this.state;
    const {
      homepage,
      subscriptionPage = false,
      singlePost,
      hideTeam,
      darkMode,
      sidePanel,
      student,
      isAdmin,
      referenceId,
      showTeamSection,
      showCreditPlan,
      showSignIn,
      showExploreMore,
      quaterlySubscription,
    } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    let individualPlans = plansData.individual
      ? JSON.parse(JSON.stringify(plansData.individual))
      : false;
    let currentTeamPlan = {
      title:
        teamSubscription.plan?.tmc_group == "team_credit"
          ? "Corporate - Privilege Credits"
          : "Corporate - Seat based plan",
      // footerTextBefore: 'Want to cancel your subscription?',
      footerButtonText:
        teamSubscription.is_team_owner &&
          teamSubscription.is_cancelled == false &&
          teamSubscription.can_cancel &&
          isAdmin != true
          ? "Cancel Subscription"
          : false,
      buttonText: false,
      footerTextAfter: teamSubscription.is_cancelled ? (
        <Text>
          This plan was cancelled and will continue to be active till{" "}
          <Text>
            {moment(teamSubscription.end_date).format("Do MMMM YYYY")}
          </Text>
          .
        </Text>
      ) : teamSubscription.is_team_owner ? (
        teamSubscription.is_team_member ? (
          false
        ) : (
          <Text>
            You are not a member of this plan but you can manage this plan for
            your organization.
          </Text>
        )
      ) : (
        <Text>
          Only the owner can update, cancel or remove you from this plan.
        </Text>
      ),
    };
    let currentPlan = {
      title: userSubscription.plan?.invoice_name,
      // footerTextBefore: 'Want to cancel your subscription?',
      footerButtonText:
        userSubscription.is_cancelled == false &&
          userSubscription.can_cancel &&
          isAdmin != true
          ? "Cancel Subscription"
          : false,
      buttonText: false,
      footerTextAfter:
        userSubscription.is_cancelled &&
          userSubscription.plan?.tmc_group != "gift_plan" ? (
          <Text>
            This plan was cancelled and will continue to be active till{" "}
            <Text>
              {moment(userSubscription.end_date).format("Do MMMM YYYY")}
            </Text>
            .
          </Text>
        ) : (
          false
        ),
    };
    let giftPlan =
      userSubscription.plan?.tmc_group == "gift_plan" ? true : false;
    let checkDesktop = this.props.sidePanel
      ? false
      : helpers.isDesktop()
        ? true
        : false;
    let multiView =
      individualPlans &&
        (((userSubscription || teamSubscription) && individualPlans.length > 1) ||
          (userSubscription && teamSubscription && individualPlans.length > 0))
        ? true
        : false;

    let showStudentSection = false;
    if (showCreditPlan && creditPlan) {
      showStudentSection = true;
    }
    if (individualPlans) {
      // individualPlans.push(creditPlan);
      if (plansData && plansData["free"]) {
        individualPlans = [
          ...plansData["free"],
          // ...plansData["credit"],
          ...individualPlans,
        ];
        console.log("individualPlansfree:", individualPlans);
      } else {
        individualPlans.push(creditPlan);
      }
    } else {
      individualPlans = [creditPlan];
    }
    if (sidePanel) {
      showStudentSection = false;
    }
    let webView = helpers.isWeb();

    let btnClickStudents, btnClickTeams, btnClickGift;

    if (helpers.isWeb()) {
      btnClickStudents = {
        to: "/pricing/student",
      };
      btnClickTeams = {
        to: "/pricing/corporate",
      };
      btnClickGift = {
        to: "/gifting",
      };
    } else {
      btnClickStudents = {
        onPress: () => this.props.navigateScreen("/student-subscription"),
      };
      btnClickTeams = {
        onPress: () => this.props.navigateScreen("/team-subscription"),
      };
      btnClickGift = {
        onPress: () => this.props.navigateScreen("/gifting"),
      };
    }

    const showActiveCorporatePlan = () => {
      if (!teamSubscription) return <></>
      return <View style={{ display: 'flex', alignItems: 'center', width: '80%', rowGap: 10, backgroundColor: '#FFF3E3', borderColor: '#E7D6C9', borderWidth: 1, paddingBottom: 20 }}>
        <View style={{ marginTop: 20 }}>
          <Text style={{ color: '#051462', fontWeight: 600, fontSize: 24, textAlign: 'center' }}>{"Corporate Plan"}</Text>
        </View>
        <View style={{ width: '80%', display: 'flex', flexDirection: 'column', columnGap: 10, justifyContent: 'center', rowGap: 10, marginTop: teamSubscription?.plan?.price > 0 ? 30 : 0 }}>
          {corporateData?.description?.access_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
            <CustomImage
              source={greenTick}
              style={{ width: 10, height: 10, objectFit: 'contain' }}
              webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
              className={'green-tick-icon'}
              altText={name}
            />
            <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
          </View>)}
        </View>
        <TouchableOpacity onPress={() => {
          if (teamSubscription.is_team_owner) {
            return window.location.href = '/my-team'
          } else return;
        }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#051462' }}>
          <Text style={{ fontSize: 14, fontWeight: 700, color: 'white' }}>{teamSubscription?.is_team_owner ? "MANAGE TEAM" : "CURRENT PLAN"}</Text>
        </TouchableOpacity>
        {teamSubscription.is_team_owner ? <TouchableOpacity onPress={() => {
          this.cancelPlan()
        }}>
          <Text style={{ textDecorationLine: 'underline' }}>Cancel Subscription</Text>
        </TouchableOpacity>
          :
          null}
      </View>
    }


    const showActivePlan = () => {
      console.log("Corporate debugging:    ", userSubscription, this.state, this.props)
      if (teamSubscription) return showActiveCorporatePlan()
      if (!userSubscription) return <></>
      return <View style={{ display: 'flex', alignItems: 'center', width: '80%', rowGap: 10, backgroundColor: '#FFF3E3', borderColor: '#E7D6C9', borderWidth: 1, paddingBottom: 20 }}>
        <View style={{ marginTop: 20 }}>
          <Text style={{ color: '#051462', fontWeight: 600, fontSize: 24, textAlign: 'center' }}>{userSubscription?.plan?.description?.display_name?.toUpperCase() || userSubscription?.plan?.name || "Friends of TMC"}</Text>
        </View>
        <View>
          {userSubscription?.plan?.price > 0 && (
            <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
              {userSubscription?.plan?.currency_code === 'INR'
                ? `₹${userSubscription?.plan?.price}`
                : `$${userSubscription?.plan?.price}`}
            </Text>
          )}
        </View>
        <View style={{ width: '80%', display: 'flex', flexDirection: 'column', columnGap: 10, justifyContent: 'center', rowGap: 10, marginTop: userSubscription?.plan?.price > 0 ? 30 : 0 }}>
          {userSubscription?.plan?.description?.access_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
            <CustomImage
              source={greenTick}
              style={{ width: 10, height: 10, objectFit: 'contain' }}
              webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
              className={'green-tick-icon'}
              altText={name}
            />
            <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
          </View>)}
        </View>
        <TouchableOpacity onPress={() => {
        }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#051462' }}>
          <Text style={{ fontSize: 14, fontWeight: 700, color: 'white' }}>{"YOUR CURRENT PLAN"}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {
          this.cancelPlan()
        }}>
          <Text style={{ textDecorationLine: 'underline' }}>Cancel Subscription</Text>
        </TouchableOpacity>
      </View>
    }

    let userHasGiftPlan = userSubscription?.plan?.tmc_group == "gift_plan";

    // Code changes to support the $3 plan
    individualPlans = individualPlans.map((plan) => {
      if (!(userSubscription && userSubscription.plan?.tmc_group != "free") && plan?.trial?.has_trial) {
        return {
          ...plan,
          price:
            plan.trial?.trial_price +
            " for " +
            plan.trial?.trial_period +
            " days",
          description: {
            ...plan.description,
            access_desc: plan.description.trial_desc,
          },
          priceHelperText: "New Users Only",
        };
      }
      return plan;
    });

    console.log("Debugging sidebar account section:>", this.props)
    console.log("Debugging sidebar account section:>", this.state)

    return (
      <>
        {(this.state.userSubscription?.status === 'active' || this.state.teamSubscription?.status === 'active') ?
          <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '5vh' }}>
            {helpers.isDesktop() ? showActivePlan() : null}
            {!helpers.isDesktop() && this.state.teamSubscription?.status === 'active' ? showActivePlan() : null}
          </View> : null}
        {(!this.state.hasUserMovedOverseas || (this.state?.userSubscription?.status === 'active')) && helpers.isDesktop() && <View style={{ display: 'flex', flexDirection: sidePanel ? 'column' : 'row', rowGap: sidePanel ? '5vh' : null, justifyContent: 'center', marginVertical: 40, columnGap: 40 }}>
          {/* Plans array starts here */}
          {
            this.state.plansData && this.state.plansData.individual?.filter((plan) => {
              if (!(this.state?.userSubscription?.status === 'active')) return true
              if (['team', 'friends-of-tmc'].includes(this.state.userSubscription?.plan?.tmc_group)) return true
              console.log("User info plans", parseInt(plan.chargebee_price) / 100, " and ", this.state.userSubscription.subscribed_plan_amount)
              if ((this.state?.userSubscription?.status === 'active') && this.state.hasUserMovedOverseas) return false
              if (this.state.userSubscription?.plan?.id == 'institution-plan-inr') return false
              if (this.state.userSubscription?.plan?.tmc_group === 'exclusive') return false
              return parseInt(plan.chargebee_price) > parseInt(this.state.userSubscription?.plan?.chargebee_price)
            }).map((plan) =>
              <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 20, width: sidePanel ? '80%' : '27%', minHeight: 400, borderColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : '#E7D6C9', borderWidth: 1, alignSelf: sidePanel ? 'center' : null }}>
                {plan.description.remark ? plan.description.remark === "MOST POPULAR" ? this.mostPopularPlanTag() : <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '60%', height: 30, bottom: 35, alignSelf: 'center' }}>
                  <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>{plan.description.remark}</Text>
                </View> : <></>}
                <View style={plan.description.remark ? { position: 'relative', bottom: 50, display: 'flex', rowGap: 20 } : { display: 'flex', rowGap: 20, }}>
                  <View>
                    <View>
                      <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description?.display_name?.toUpperCase()}</Text>
                    </View>
                    <View>
                      <Text style={{ color: '#051462', fontSize: 18 }}>{plan.name}</Text>
                    </View>
                  </View>
                  <View>
                    <View>
                      <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
                        {plan.description.is_annual ?
                          `${plan.currency_code === "USD" ?
                            `$${plan.description.monthly_price} / month`
                            : `₹${plan.description.monthly_price} / month`
                          }` :
                          `${plan.currency_code === "USD" ?
                            `$${parseFloat(plan.chargebee_price) / 100} / month`
                            : `₹${parseInt(plan.chargebee_price) / 100} / month`}
                          `.trim()}
                      </Text>
                    </View>
                    <View>
                      <Text style={{ color: '#051462', fontSize: 14 }}>
                        {plan.description.is_annual ?
                          plan.description.display_name === "Ace" ?
                            <Text>Billed {plan.currency_code === "USD" ? <Text> <Text style={{ textDecorationLine: 'line-through' }}>$250</Text> ${parseFloat(plan.chargebee_price) / 100} </Text> :
                              <Text> <Text style={{ textDecorationLine: 'line-through' }}>₹6898</Text> ₹{parseFloat(plan.chargebee_price) / 100} </Text>} now for two year access</Text>
                            :
                            <Text>Billed {plan.currency_code === "USD" ? <Text><Text style={{ textDecorationLine: 'line-through' }}>$125</Text> ${parseFloat(plan.chargebee_price) / 100}</Text> :
                              <Text><Text style={{ textDecorationLine: 'line-through' }}>₹3449</Text> ₹{parseFloat(plan.chargebee_price) / 100}</Text>} now for annual access</Text>
                          :
                          <Text>Billed every month</Text>}
                      </Text>
                      {/* {plan.description.is_annual ?
                          <Text style={{ fontWeight: 'bold', color: '#117A51' }}>Ends 29th February at 11:59 PM {plan.currency_code === "USD" ? "PST" : "IST"}</Text>
                          :
                          null} */}
                    </View>
                  </View>
                  <TouchableOpacity onPress={() => {
                    if (this.state.isButtonClicked) return
                    this.setState({ isButtonClicked: true })
                    if (!this.state.token) {
                      this.props.navigateToScreen(
                        `/sign-in?redirect=/pricing&redirect-params=subscribe=${plan.id}&quantity=1`
                      );
                      return
                    }
                    /**
                     * Function to open chargebee box
                     */
                    if (['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                      return
                    }
                    if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id) {
                      /**
                       * Renew the current plan
                       */

                      this.handleRenew()
                      return
                    }
                    this.setState({ selectedSubscribtion: { id: plan.id, billing_enitity_type: plan.billing_enitity_type, price: plan.chargebee_price }, selectedSubscribtionID: plan.id })
                    setTimeout(() => this.proceedToCheckoutScreen(), 0)
                  }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : null, opacity: ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null }}>
                    <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: plan.description.remark && plan.description.remark === "MOST POPULAR" ? 'white' : 'black' }}>
                      {(this.state?.userSubscription?.status === 'active') ? "UPGRADE" : this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id ? "RENEW" : "SELECT PLAN"}
                    </Text>
                  </TouchableOpacity>
                  <View>
                    <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                  </View>
                  {plan.description.access_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                    <CustomImage
                      source={greenTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
                  </View>)}
                  {plan.description?.limitations_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                    <CustomImage
                      source={crossIconRed}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                    <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
                  </View>)}
                </View>
              </View>)
          }
          {/* Plans array ends here */}
        </View>}
        {
          this.state.selectedPlanIdx === 0 && this.state.hasUserMovedOverseas && !(this.state?.userSubscription?.status === 'active') ? <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginVertical: 40, columnGap: 40 }}>
            {/* Plans array starts here */}
            {
              this.state.overseasShiftedUsersPlanData && this.state.overseasShiftedUsersPlanData?.map((plan) => {
                console.log("Debug overseas plans: ", plan)
                return <View style={{ display: 'flex', backgroundColor: '#FFF3E3', paddingHorizontal: 20, paddingVertical: 20, rowGap: 20, width: '27%', minHeight: 400, borderColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : '#E7D6C9', borderWidth: 1 }}>
                  {plan.description.remark ? plan.description.remark === "MOST POPULAR" ? this.mostPopularPlanTag() : <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#D5F0E6', position: 'relative', width: '60%', height: 30, bottom: 35, alignSelf: 'center' }}>
                    <Text style={{ fontSize: 14, color: '#117A51', fontWeight: 500 }}>{plan.description.remark}</Text>
                  </View> : <></>}
                  <View style={plan.description.remark ? { position: 'relative', bottom: 50, display: 'flex', rowGap: 20 } : { display: 'flex', rowGap: 20, }}>
                    <View>
                      <View>
                        <Text style={{ color: '#051462', fontWeight: 600, fontSize: 32 }}>{plan.description?.display_name?.toUpperCase()}</Text>
                      </View>
                      <View>
                        <Text style={{ color: '#051462', fontSize: 18 }}>{plan.name}</Text>
                      </View>
                    </View>
                    <View>
                      <View>
                        <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
                          {plan.description.is_annual ?
                            `${plan.currency_code === "USD" ?
                              `$${plan.description.monthly_price} / month`
                              : `₹${plan.description.monthly_price} / month`
                            }` :
                            `${plan.currency_code === "USD" ?
                              `$${parseFloat(plan.chargebee_price) / 100} / month`
                              : `₹${parseInt(plan.chargebee_price) / 100} / month`}
                          `.trim()}
                        </Text>
                      </View>
                      <View>
                        <Text style={{ color: '#051462', fontSize: 14 }}>
                          {plan.description.is_annual ?
                            plan.description.display_name === "Ace" ?
                              <Text>Billed {plan.currency_code === "USD" ? <Text> <Text style={{ textDecorationLine: 'line-through' }}>$250</Text> ${parseFloat(plan.chargebee_price) / 100} </Text> :
                                <Text> <Text style={{ textDecorationLine: 'line-through' }}>₹6898</Text> ₹{parseFloat(plan.chargebee_price) / 100} </Text>} now for two year access</Text>
                              :
                              <Text>Billed {plan.currency_code === "USD" ? <Text><Text style={{ textDecorationLine: 'line-through' }}>$125</Text> ${parseFloat(plan.chargebee_price) / 100}</Text> :
                                <Text><Text style={{ textDecorationLine: 'line-through' }}>₹3449</Text> ₹{parseFloat(plan.chargebee_price) / 100}</Text>} now for annual access</Text>
                            :
                            <Text>Billed every month</Text>}
                        </Text>
                        {/* {plan.description.is_annual ?
                            <Text style={{ fontWeight: 'bold', color: '#117A51' }}>Ends 29th February at 11:59 PM {plan.currency_code === "USD" ? "PST" : "IST"}</Text>
                            :
                            null} */}
                      </View>
                    </View>
                    <TouchableOpacity onPress={() => {
                      if (this.state.isButtonClicked) return
                      this.setState({ isButtonClicked: true })
                      if (!this.state.token) {
                        this.props.navigateToScreen(
                          `/sign-in?redirect=/pricing&redirect-params=subscribe=${plan.id}&quantity=1`
                        );
                        return
                      }
                      /**
                       * Function to open chargebee box
                       */
                      if (['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                        return
                      }
                      if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id) {
                        /**
                         * Renew the current plan
                         */

                        this.handleRenew()
                        return
                      }
                      this.setState({ selectedSubscribtion: { id: plan.id, billing_enitity_type: plan.billing_enitity_type, price: plan.chargebee_price }, selectedSubscribtionID: plan.id })
                      setTimeout(() => this.proceedToCheckoutScreen(), 0)
                    }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, backgroundColor: plan.description.remark && plan.description.remark === "MOST POPULAR" ? '#1E1B4B' : null, opacity: ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null }}>
                      <Text style={{ textAlign: 'center', fontWeight: 600, fontSize: 17, color: plan.description.remark && plan.description.remark === "MOST POPULAR" ? 'white' : 'black' }}>
                        {(this.state?.userSubscription?.status === 'active') ? "UPGRADE" : this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.id === plan.id ? "RENEW" : "SELECT PLAN"}
                      </Text>
                    </TouchableOpacity>
                    <View>
                      <Text style={{ color: '#051462', fontSize: 16, fontWeight: 600 }}>What's Included</Text>
                    </View>
                    {plan.description.access_desc.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                      <CustomImage
                        source={greenTick}
                        style={{ width: 10, height: 10, objectFit: 'contain' }}
                        webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                        className={'green-tick-icon'}
                        altText={name}
                      />
                      <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
                    </View>)}
                  </View>
                </View>
              })
            }
            {/* Plans array ends here */}
          </View> : null
        }
        {
          !helpers.isDesktop() && !this.state.teamSubscription?.status === 'active' && <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginVertical: 40, columnGap: 40 }}>
            {/* Individual pricing table */}
            {this.state.userSubscription?.plan?.tmc_group !== 'exclusive' ? <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '10vh' }}>
              <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', rowGap: '3vh' }}>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  {this.state.individualPlanData?.plans && Object.keys(this.state.individualPlanData?.plans)?.filter((plan) => {
                    if (!(this.state?.userSubscription?.status === 'active')) return true;
                    const currentPlanID = this.state.userSubscription?.plan?.id;
                    if (!currentPlanID || currentPlanID.includes('monthly-plan')) {
                      return true
                    } else if (currentPlanID.includes('annual-plan') && plan.includes('monthly-plan')) {
                      return false;
                    } else if (currentPlanID.includes('2-year-plan') && (plan.includes('monthly-plan') || plan.includes('annual-plan'))) {
                      return false;
                    }
                    return true;
                  })?.map((plan) => <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 10, fontWeight: 500, textAlign: 'center', height: '3vh', marginBottom: '-2vh' }}>{this.state.individualPlanData?.plans[plan]["remark"]}</Text>
                  </View>)}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  {this.state.individualPlanData?.plans && Object.keys(this.state.individualPlanData?.plans)?.filter((plan) => {
                    if (!(this.state?.userSubscription?.status === 'active')) return true;
                    const currentPlanID = this.state.userSubscription?.plan?.id;
                    if (!currentPlanID || currentPlanID.includes('monthly-plan')) {
                      return true
                    } else if (currentPlanID.includes('annual-plan') && plan.includes('monthly-plan')) {
                      return false;
                    } else if (currentPlanID.includes('2-year-plan') && (plan.includes('monthly-plan') || plan.includes('annual-plan'))) {
                      return false;
                    }
                    return true;
                  })?.map((plan, idx) => <TouchableWithoutFeedback onPress={() => {
                    console.log("Clicked here")
                    this.setState({ selectedIndividualPlanIdx: idx })
                    this.setState({ selectedSubscribtion: { id: (this.state.hasUserMovedOverseas && !(this.state?.userSubscription?.status === 'active')) ? (idx === 0 && '2-year-plan-iin-usd') || (idx === 1 && 'annual-plan-in-usd') || (idx === 2 && 'monthly-plan-in-usd') : plan, billing_enitity_type: this.state.individualPlanData?.plans[plan]["billing_entity_type"], price: this.state.individualPlanData?.plans[plan]["amount"] }, selectedSubscribtionID: plan })
                  }}>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: this.state.selectedIndividualPlanIdx === idx ? '#128D5C' : 'rgba(18, 141, 92, 0.70)', paddingHorizontal: '1vw', paddingVertical: '1vh', width: '30%' }}>
                      <View>
                        <Text style={{ color: 'white', fontSize: 16, fontWeight: 700, textAlign: 'center' }}>{this.state.individualPlanData?.plans[plan]["display_name"]?.toUpperCase()}</Text>
                      </View>
                      <View>
                        <Text style={{ color: 'white', fontSize: 12, fontWeight: 400, textAlign: 'center' }}>{this.state.individualPlanData?.plans[plan]["plan_name"]}</Text>
                      </View>
                      {this.state.selectedIndividualPlanIdx === idx && <View style={{ position: 'relative', backgroundColor: '#128D5C', transform: 'rotate(45deg)', top: '1.5vh', width: '1.5vh', height: '1.5vh' }} />}
                    </View>
                  </TouchableWithoutFeedback>)}
                </View>
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Subscription price
                  </Text>
                  {/* <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    (Offer ends 29th February at 11:59 PM {this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ? "PST" : "IST"})
                  </Text> */}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', width: '90%' }}>
                  {this.state.individualPlanData?.plans && Object.keys(this.state.individualPlanData?.plans)?.filter((plan) => {
                    if (!(this.state?.userSubscription?.status === 'active')) return true;
                    const currentPlanID = this.state.userSubscription?.plan?.id;
                    if (!currentPlanID || currentPlanID.includes('monthly-plan')) {
                      return true
                    } else if (currentPlanID.includes('annual-plan') && plan.includes('monthly-plan')) {
                      return false;
                    } else if (currentPlanID.includes('2-year-plan') && (plan.includes('monthly-plan') || plan.includes('annual-plan'))) {
                      return false;
                    }
                    return true;
                  })?.map((plan, idx) => <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ fontSize: 14, fontWeight: 500, textAlign: 'center', color: this.state.selectedIndividualPlanIdx === idx ? '#128D5C' : '#1E1B4B' }}>
                      {this.state.individualPlanData?.plans[plan]["display_name"] === "Smart" ?
                        `${this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!(this.state?.userSubscription?.status === 'active') && this.state.hasUserMovedOverseas) ?
                          `$8.25 / month`
                          : `₹249 / month`
                        }` : this.state.individualPlanData?.plans[plan]["display_name"] === "Ace" ?
                          `${this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!(this.state?.userSubscription?.status === 'active') && this.state.hasUserMovedOverseas) ?
                            `$7.29 / month`
                            : `₹225 / month`
                          }` :
                          `${this.state.individualPlanData?.plans[plan]["display_name"] === "Starter"
                            && this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!(this.state?.userSubscription?.status === 'active') && this.state.hasUserMovedOverseas) ?
                            `$9.99 / month`
                            : `₹499 / month`}
                          `.trim()}
                    </Text>
                    <Text style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', color: this.state.selectedIndividualPlanIdx === idx ? '#128D5C' : '#1E1B4B' }}>
                      {this.state.individualPlanData?.plans[plan]["display_name"] === "Smart" ?
                        <Text>{this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!(this.state?.userSubscription?.status === 'active') && this.state.hasUserMovedOverseas) ?
                          <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>$125</Text> $99 now for annual access</Text>
                          : <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>₹3,449</Text> ₹2,999 now for annual access</Text>
                        }</Text> : this.state.individualPlanData?.plans[plan]["display_name"] === "Ace" ?
                          <Text>{this.state.individualPlanData?.plans[plan]["currency"] === "USD" || (!(this.state?.userSubscription?.status === 'active') && this.state.hasUserMovedOverseas) ?
                            <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>$250</Text> $175 now for annual access</Text>
                            : <Text>Billed <Text style={{ textDecorationLine: 'line-through' }}>₹6,898</Text> ₹5,500 now for annual access</Text>
                          }</Text> :
                          `${this.state.individualPlanData?.plans[plan]["display_name"] === "Starter" ?
                            `Billed every month`
                            : `Billed every month`}
                          `}
                    </Text>
                  </View>)}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Access to stories
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 0 ? '#128D5C' : '#1E1B4B' }}>
                      Everything we publish for two years
                    </Text>
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 1 ? '#128D5C' : '#1E1B4B' }}>
                      Everything we publish for one year
                    </Text>
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 2 ? '#128D5C' : '#1E1B4B' }}>
                      50 stories every month
                    </Text>
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Archive Access
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 0 ? '#128D5C' : '#1E1B4B' }}>
                      Unlimited access to our archives of 1000+ stories
                    </Text>
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ color: '#1E1B4B', fontSize: 14, fontWeight: 500, textAlign: 'center', height: '7vh', color: this.state.selectedIndividualPlanIdx === 1 ? '#128D5C' : '#1E1B4B' }}>
                      Limited access to archives for one year
                    </Text>
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%' }}>
                    {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) &&
                      <View style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomImage
                          source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                          style={{ width: 10, height: 10, objectFit: 'contain' }}
                          webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                          className={'green-tick-icon'}
                          altText={name}
                        />
                      </View>}
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Monthly subscriptions you can gift to friends
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%' }}>
                    <Text style={{ fontSize: 14, color: '#1E1B4B', fontWeight: 500, textAlign: 'center', color: this.state.selectedIndividualPlanIdx === 0 ? '#128D5C' : '#1E1B4B' }}>2</Text>
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Gift 3 subscriber only stories to friends monthly
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 0 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Comment on stories and be part of the subscriber community
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 0 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                  <Text style={{ textAlign: 'center', width: '100%', fontSize: 14, fontWeight: 700, lineHeight: '150%', color: '#1E1B4B' }}>
                    Privilege seats for subscriber only events
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', alignItems: 'center', width: '90%' }}>
                  <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 0 ? greenTick : darkTick}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>
                  {(this.state.userSubscription?.plan?.id?.includes('annual-plan') || this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 1 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                  {(this.state.userSubscription?.plan?.id?.includes('monthly-plan') || !(this.state?.userSubscription?.status === 'active') || ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) && <View style={{ display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center' }}>
                    <CustomImage
                      source={this.state.selectedIndividualPlanIdx === 2 ? lockIcon : lockIconDark}
                      style={{ width: 10, height: 10, objectFit: 'contain' }}
                      webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
                      className={'green-tick-icon'}
                      altText={name}
                    />
                  </View>}
                </View>
                <View style={{ height: 1, width: '90%', backgroundColor: '#E7D6C9' }} />
                <Button
                  loader={this.state.isButtonClicked}
                  title={(this.state?.userSubscription?.status === 'active') &&
                    ((this.state.userSubscription?.plan?.description?.display_name === "Ace" && this.state.selectedIndividualPlanIdx === 0) ||
                      (this.state.userSubscription?.plan?.description?.display_name === "Smart" && this.state.selectedIndividualPlanIdx === 1) ||
                      (this.state.userSubscription?.plan?.tmc_group === "individual" && this.state.selectedIndividualPlanIdx === 2 && !this.state.userSubscription?.plan?.description?.is_annual))
                    ? "CURRENT PLAN" : this.isSubscriptionUnderDunning() &&
                      this.state.userSubscription?.due_since !== undefined && ((this.state.userSubscription?.plan?.description?.display_name === "Ace" && this.state.selectedIndividualPlanIdx === 0) ||
                        (this.state.userSubscription?.plan?.description?.display_name === "Smart" && this.state.selectedIndividualPlanIdx === 1) ||
                        (this.state.userSubscription?.plan?.tmc_group === "individual" && this.state.selectedIndividualPlanIdx === 2 && !this.state.userSubscription?.plan?.description?.is_annual)) ? "RENEW" :
                      (this.state?.userSubscription?.status === 'active') ? "UPGRADE" : "SUBSCRIBE"}
                  rootStyle={{
                    btnWrapper: [
                      true
                        ? button.primary
                        : button.primary,
                      { height: 36, cursor: "pointer", marginTop: 25, width: '80%', zIndex: -5, alignItems: 'center', justifyContent: 'center', opacity: ['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group) || this.state.isButtonClicked ? 0.5 : null, backgroundColor: '#1E1B4B' },
                    ],
                    btnText: [
                      true
                        ? button.primaryText
                        : button.primaryText,
                      { fontWeight: "700", paddingHorizontal: 15, fontSize: 17, fontFamily: "HaasGrotesk-Medium" },
                    ],

                  }}
                  onClick={() => {
                    if (this.state.isButtonClicked) return
                    this.setState({ isButtonClicked: true })
                    if (this.state.selectedIndividualPlanIdx === 0 && !helpers.isDesktop() && this.state.selectedPlanIdx === 0 && !this.state.selectedSubscribtionID.includes('2-year-plan')) {
                      this.setState(
                        {
                          selectedSubscribtionID:
                            this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                              '2-year-plan-usd' :
                              `2-year-plan-inr`,
                          selectedSubscribtion: {
                            id: this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code !== "IN" ?
                              '2-year-plan-usd' :
                              `2-year-plan-inr`,
                            billing_enitity_type: "plan",
                            price: 5500
                          },
                        },
                        () => {
                          this.proceedToCheckoutScreen()
                        }
                      )
                      return
                    }
                    if (!this.state.token) {
                      let selectedPlanHere = '2-year-plan-inr'
                      if (this.state.locationInfo?.countryInfo?.code && this.state.locationInfo?.countryInfo?.code === "IN") {
                        if (this.state.selectedIndividualPlanIdx === 0) {
                          selectedPlanHere = '2-year-plan-inr'
                        } else if (this.state.selectedIndividualPlanIdx === 1) {
                          selectedPlanHere = 'annual-plan-in'
                        } else {
                          selectedPlanHere = 'monthly-plan-inr'
                        }
                      } else {
                        if (this.state.selectedIndividualPlanIdx === 0) {
                          selectedPlanHere = '2-year-plan-usd'
                        } else if (this.state.selectedIndividualPlanIdx === 1) {
                          selectedPlanHere = 'annual-plan-usd'
                        } else {
                          selectedPlanHere = 'monthly-plan-usd'
                        }
                      }
                      this.props.navigateToScreen(
                        `/sign-in?redirect=/pricing&redirect-params=subscribe=${selectedPlanHere}&quantity=1`
                      );
                      return
                    }
                    if (['friends-of-tmc', 'team'].includes(this.state.userSubscription?.plan?.tmc_group)) {
                      return
                    }
                    if (this.isSubscriptionUnderDunning() && this.state.userSubscription?.due_since !== undefined && this.state.userSubscription?.plan?.tmc_group === 'individual') {
                      if ((this.state.userSubscription?.plan?.id.includes('annual-plan') && this.state.selectedIndividualPlanIdx === 1)) {
                        this.handleRenew()
                        return
                      }
                      if ((this.state.userSubscription?.plan?.id.includes('2-year-plan') && this.state.selectedIndividualPlanIdx === 0)) {
                        this.handleRenew()
                        return
                      }
                      if ((this.state.userSubscription?.plan?.id.includes('monthly-plan') && this.state.selectedIndividualPlanIdx === 2)) {
                        this.handleRenew()
                        return
                      }
                      if (this.isSubscriptionUnderDunning() &&
                        this.state.userSubscription?.due_since !== undefined) {
                        return
                      }
                      this.setState({
                        selectedSubscribtion: {
                          id: this.state.userSubscription?.plan?.id,
                          billing_enitity_type: this.state.userSubscription?.plan?.billing_enitity_type,
                          price: this.state.userSubscription?.plan?.price
                        },
                        selectedSubscribtionID: this.state.userSubscription?.plan?.id
                      })
                      setTimeout(() => this.proceedToCheckoutScreen(), 0)
                      return
                    }
                    setTimeout(() => this.proceedToCheckoutScreen(), 0)
                  }}
                />
              </View>
            </View> :
              <View style={{ marginTop: 20, display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                {this.showActivePlanMob()}
              </View>
            }
            {/* Individual pricing table ends here */}
          </View>
        }
        {checkDesktop && teamSubscription.is_team_member && (
          <View style={Flex.alignCenter}>
            {userSubscription ? (
              <></>
            ) : (
              <Text
                style={[
                  Padding.ph_2,
                  {
                    color: "#000000",
                    fontFamily: FontFamily.regular,
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: 16,
                  },
                  darkModeOption && { color: Colors.lightWhite },
                ]}
              >
                Individual plans are not available since you are already a
                member of a team plan.
              </Text>
            )}
          </View>
        )}
        {/* {showStudentSection && Platform.OS != 'ios' &&
                    <View style={[{ paddingHorizontal: 20, marginBottom: 12 }, helpers.isDesktop() && Margin.mt_1, homepage && { backgroundColor: darkModeOption ? Colors.darkBlackColor : '#fbf5ff' }]}>
                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 22 : 18, lineHeight: helpers.isDesktop() ? 22 : 20, color: darkModeOption ? Colors.lineColor : 'rgba(0, 0, 0, 1)', textAlign: 'center', marginBottom: 5 }}>
                            <Text style={{ fontWeight: helpers.isWeb() ? '600' : '700' }}>
                                TMC for students
                            </Text>
                        </Text>
                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, color: darkModeOption ? Colors.lineColor : 'rgba(0, 0, 0, 1)', textAlign: 'center', paddingHorizontal: helpers.isDesktop() ? 180 : 10 }}>
                            <TouchableOpacityLink {...btnClickStudents} >
                                <Text style={[{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, textDecorationLine: 'underline', color: darkModeOption ? Colors.lineColor : Colors.brandColor1, textAlign: 'center' }, !helpers.isWeb() && { top: 3, position: 'relative' }]}>
                                    Click here
                                </Text>
                            </TouchableOpacityLink> to Get 50% discount on our annual subscription and access to our exclusive student community on Telegram
                        </Text>
                        {showSignIn &&
                            <View style={[Padding.ph_2, Flex.alignCenter, Flex.row, { justifyContent: 'center', paddingBottom: 20, paddingTop: 20 }]}>
                                <Text style={[{ fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, fontFamily: FontFamily.regular, fontWeight: helpers.isWeb() ? '600' : '700', marginBottom: 0, color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Already a member?</Text>
                                <Button
                                    title={'Sign in'}
                                    rootStyle={{
                                        btnWrapper: [{ marginLeft: 5 }],
                                        btnText: [{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 20 : 18, textDecorationLine: 'underline', color: darkMode ? Colors.lineColor : Colors.brandColor1 }]
                                    }}
                                    onClick={() => this.props.goToLogin()}
                                />
                            </View>
                        }
                    </View>
                } */}

        {/* {showTeamSection && Platform.OS != 'ios' &&
                    <View style={[{ paddingHorizontal: 20 }, helpers.isDesktop() && Margin.mt_1, homepage && { backgroundColor: darkModeOption ? Colors.darkBlackColor : '#fbf5ff' }]}>
                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, color: darkModeOption ? Colors.lineColor : 'rgba(0, 0, 0, 1)', textAlign: 'center' }}><Text style={{ fontWeight: helpers.isWeb() ? '600' : '700' }}>Are you’ll a team?</Text> <TouchableOpacityLink {...btnClickTeams} >
                            <Text style={[{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, textDecorationLine: 'underline', color: darkModeOption ? Colors.lineColor : Colors.brandColor1, textAlign: 'center' }, !helpers.isWeb() && { top: 3, position: 'relative' }]}>Click here</Text>
                        </TouchableOpacityLink> to know about our Corporate subscription plan.</Text>
                    </View>
                } */}

        {userSubscription == false && teamSubscription == false && isAdmin && (
          <View
            style={[
              { paddingHorizontal: 20 },
              helpers.isDesktop() && Margin.mt_1,
              homepage && {
                backgroundColor: darkModeOption
                  ? Colors.darkBlackColor
                  : "#fbf5ff",
              },
            ]}
          >
            <Text
              style={{
                fontFamily: FontFamily.regular,
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 22 : 20,
                color: darkModeOption ? Colors.lineColor : "rgba(0, 0, 0, 1)",
                textAlign: "center",
              }}
            >
              User does not have a plan.
            </Text>
          </View>
        )}

        {showExploreMore && (
          <View
            style={{
              flexDirection: helpers.isDesktop() ? "row" : "column",
              marginTop: 30,
              marginBottom: 70,
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              alignItems: helpers.isDesktop() ? "center" : "flex-start",
              justifyContent: helpers.isDesktop() ? "center" : "flex-start",
              paddingHorizontal: singlePost ? 0 : 20,
            }}
          >
            <View
              style={[
                {
                  paddingRight: helpers.isDesktop() ? 40 : 0,
                  marginBottom: helpers.isDesktop() ? 0 : 40,
                },
                !helpers.isDesktop() && {
                  alignItems: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: 18,
                    fontFamily: FontFamily.regular,
                    fontWeight: "600",
                    color: darkMode ? Colors.lightWhite : "rgba(0,0,0,0.7)",
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                    marginBottom: 5,
                  },
                  !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                ]}
              >
                Explore more
              </Text>
              <Text
                style={[
                  {
                    fontSize: 18,
                    fontFamily: FontFamily.regular,
                    fontWeight: "600",
                    color: darkMode ? Colors.lightWhite : "rgba(0,0,0,0.7)",
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                    marginBottom: 5,
                  },
                  !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                ]}
              >
                subscription
              </Text>
              <Text
                style={[
                  {
                    fontSize: 18,
                    fontFamily: FontFamily.regular,
                    fontWeight: "600",
                    color: darkMode ? Colors.lightWhite : "rgba(0,0,0,0.7)",
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                  },
                  !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                ]}
              >
                options
              </Text>
            </View>
            <View
              style={{
                borderLeftWidth: helpers.isDesktop() ? 1 : 0,
                borderLeftColor: "rgba(0, 0, 0, 0.3)",
                paddingLeft: helpers.isDesktop() ? 30 : 0,
              }}
            >
              <View style={{ flexDirection: "row", marginBottom: 20 }}>
                <TouchableOpacityLink {...btnClickStudents}>
                  <View style={{ flexDirection: "row" }}>
                    <CustomImage
                      source={studenOrangeIcon}
                      require={true}
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: "contain",
                        marginRight: 12,
                      }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                        minWidth: 30,
                      }}
                    />
                    <View style={{ width: "90%" }}>
                      <Text
                        style={{
                          fontSize: helpers.isDesktop() ? 20 : 16,
                          fontFamily: FontFamily.regular,
                          lineHeight: helpers.isDesktop() ? 32 : 22,
                          fontWeight: "500",
                          color: darkMode ? Colors.lightWhite : "#000000",
                        }}
                      >
                        Students enjoy a special discount compared to our standard subscription. Reach out for campus access.
                      </Text>
                    </View>
                  </View>
                </TouchableOpacityLink>
              </View>
              <View style={{ flexDirection: "row", marginBottom: 20 }}>
                <TouchableOpacityLink {...btnClickGift}>
                  <View style={{ flexDirection: "row" }}>
                    <CustomImage
                      source={giftOrangeIcon}
                      require={true}
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: "contain",
                        marginRight: 12,
                      }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                        minWidth: 30,
                      }}
                    />
                    <View style={{ width: "90%" }}>
                      <Text
                        style={{
                          fontSize: helpers.isDesktop() ? 20 : 16,
                          fontFamily: FontFamily.regular,
                          lineHeight: helpers.isDesktop() ? 32 : 22,
                          fontWeight: "500",
                          color: darkMode ? Colors.lightWhite : "#000000",
                        }}
                      >
                        Gift The Morning Context Subscription to friends and family.
                      </Text>
                    </View>
                  </View>
                </TouchableOpacityLink>
              </View>
              <View style={{ flexDirection: "row", marginBottom: 0 }}>
                <TouchableOpacityLink {...btnClickTeams}>
                  <View style={{ flexDirection: "row" }}>
                    <CustomImage
                      source={accesOrangeIcon}
                      require={true}
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: "contain",
                        marginRight: 12,
                      }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                        minWidth: 30,
                      }}
                    />
                    <View style={{ width: "90%" }}>
                      <Text
                        style={{
                          fontSize: helpers.isDesktop() ? 20 : 16,
                          fontFamily: FontFamily.regular,
                          lineHeight: helpers.isDesktop() ? 32 : 22,
                          fontWeight: "500",
                          color: darkMode ? Colors.lightWhite : "#000000",
                        }}
                      >
                        Get access for your entire team or organisation{" "}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacityLink>
              </View>
            </View>
          </View>
        )}

        {!helpers.isWeb() && this.props.sidePanel && (
          <View style={{ height: 60 }}></View>
        )}
      </>
    );
  };

  renderCancelSubscriptionModal = () => {
    let { darkMode, sidePanel } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    return (
      <View>
        <Text style={[Typo.h3, Helpers.textUppercase, Helpers.textCenter]}>
          CANCEL SUBSCRIPTION
        </Text>
        <PageBreak style={{ marginTop: 6 }} size={"small"} />
        <View style={[Margin.mt_2, Margin.mb_3]}>
          <Text style={[subscribePlans.cancelDesc, Helpers.textCenter]}>
            Are you sure you want to cancel your subscription?
          </Text>
        </View>
        <View style={[Margin.mb_4]}>
          <Text style={[subscribePlans.currentPlanEven, Helpers.textCenter]}>
            Current Plan:{" "}
            <Text style={[subscribePlans.currentPlanTypeEven]}>Quarterly</Text>
          </Text>
          <Text
            style={[
              subscribePlans.PlanPriceOdd,
              { color: Colors.brandColor1 },
              Helpers.textCenter,
              { marginVertical: 5 },
            ]}
          >
            ₹1199
          </Text>
          <Text
            style={[
              subscribePlans.infoText,
              Helpers.textItalic,
              Helpers.textCenter,
              { opacity: 0.7 },
            ]}
          >
            Next payment date: 1 January 2021
          </Text>
        </View>
        <View>
          <View style={[Flex.row, Margin.mb_1]}>
            <View style={[Helpers.liDot]} />
            <Text
              style={[
                Helpers.liText,
                darkModeOption && { color: Colors.lightWhite },
              ]}
            >
              It will take effect at the end of your current billing period on{" "}
              <Text style={[Helpers.textBold]}>31 December 2020</Text>
            </Text>
          </View>
          <View style={[Flex.row]}>
            <View style={[Helpers.liDot]} />
            <Text
              style={[
                Helpers.liText,
                darkModeOption && { color: Colors.lightWhite },
              ]}
            >
              You can always come back and subscribe to us again
            </Text>
          </View>
        </View>
        <View style={[Margin.mt_4]}>
          <ButtonGradient
            title={"CONFIRM"}
            rootStyle={{
              btnWrapper: button.primaryGradient,
              btnText: button.primaryGradientText,
            }}
            darkMode={darkModeOption}
          />
        </View>
        <View style={[Margin.mt_1]}>
          <Text
            style={[
              Helpers.textCenter,
              subscribePlans.footertext,
              darkModeOption && { color: Colors.lightWhite },
            ]}
          >
            Before you leave, maybe we can help you: Contact us on 180 - 1800
            for support
          </Text>
        </View>
      </View>
    );
  };

  renderUpgradeModalContent = () => {
    return (
      <View>
        <Text style={[Typo.h3, Helpers.textUppercase, Helpers.textCenter]}>
          UPGRADE PLAN
        </Text>
        <PageBreak size={"small"} />
        <View style={[Margin.mt_3]}>
          <Text style={[subscribePlans.currentPlan, Helpers.textCenter]}>
            Current Plan:{" "}
            <Text style={[subscribePlans.currentPlanType]}>Quarterly</Text>
          </Text>
          <Text style={[subscribePlans.PlanDescOdd, Helpers.textCenter]}>
            10 long reads + newsletters + complete access to TMC 1 newsletter &
            daily stories
          </Text>
          <Text style={[subscribePlans.PlanPriceOdd, Helpers.textCenter]}>
            <Text style={[subscribePlans.PlanPriceSymbolOdd]}>₹</Text> 1199
          </Text>
        </View>
        <View style={[subscribePlans.seprator, Margin.mt_2, Margin.mb_2]} />
        <View>
          <Text style={[subscribePlans.currentPlanEven, Helpers.textCenter]}>
            Current Plan:{" "}
            <Text style={[subscribePlans.currentPlanTypeEven]}>Quarterly</Text>
          </Text>
          <Text style={[subscribePlans.PlanDescEven, Helpers.textCenter]}>
            10 long reads + newsletters + complete access to TMC 1 newsletter &
            daily stories
          </Text>
          <Text style={[subscribePlans.PlanPriceEven, Helpers.textCenter]}>
            <Text style={[subscribePlans.PlanPriceSymbolEven]}>₹</Text> 1199
          </Text>
        </View>
        <View style={[Margin.mt_4]}>
          <ButtonGradient
            title={"CONFIRM CHANGE"}
            rootStyle={{
              btnWrapper: button.primaryGradient,
              btnText: button.primaryGradientText,
            }}
            darkMode={darkMode}
          />
        </View>
      </View>
    );
  };

  renderWebPage = () => {
    const { sidePanel } = this.props;
    let checkDesktop = sidePanel ? false : helpers.isDesktop ? true : false;
    return (
      <>
        {/* { checkDesktop && <PageBreak /> } */}
        {/* {this.renderPageContent()} */}
        {this.state.teamSubscription ||
          (this.state.plansData && this.state.plansData["individual"]?.length)
          ? this.renderPageContent()
          : null}
      </>
    );
  };

  renderAppPage = () => {
    return (
      <>
        <ScrollView style={{ flex: 1 }}>{this.renderPageContent()}</ScrollView>
      </>
    );
  };

  render() {
    const { pageLoader, showErrorModal } = this.state;
    const { homepage, lightBlueBg, subscriptionPage } = this.props;
    const webView = helpers.isWeb();
    let checkDesktop = this.props.sidePanel
      ? false
      : helpers.isDesktop
        ? true
        : false;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          checkDesktop && !homepage && Padding.pt_4,
          !subscriptionPage && appStyles.appBg,
          subscriptionPage && { paddingTop: 0 },
          Flex.fill,
          lightBlueBg && { backgroundColor: "#E5E5E5" },
          darkMode && { backgroundColor: Colors.darkBlackColorBg },
          homepage && {
            backgroundColor: darkMode ? Colors.darkBlackColorBg : "#ffffff",
          },
        ]}
      >
        {pageLoader
          ? this.showPageLoader()
          : webView
            ? this.renderWebPage()
            : this.renderAppPage()}
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigateToScreen("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(SubscriptionPricingPlan));
